<form [formGroup]="form">
  <td class="table-cell" colspan="2">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>
        {{element.startAt | time2string }}
        &mdash;
        {{element.endAt | time2string }}
      </span>
    </div>
    <div class="form-group" *ngIf="IsEdit" @animation>
      <div class="form-group-range">
        <div class="form-group">
          <div class="box-title">Нерабочие время</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="start" type="datetime-local" formControlName="start" (change)="changeDate()" />
            <label class="field-validation-error" *ngIf="form.get('start').touched && form.get('start').invalid">Некорректное значение</label>
          </div>
        </div>

        <div class="mdash">&mdash;</div>

        <div class="form-group">
          <div class="box-title">&nbsp;</div>
          <div class="row__field">
            <input class="form-control input__text input__text--long" name="end" type="datetime-local" formControlName="end" min="{{form.get('start').value}}" (change)="changeDate()" />
            <label class="field-validation-error" *ngIf="form.get('end').touched && form.get('end').invalid">Некорректное значение</label>
          </div>
        </div>
      </div>
    </div>
  </td>
  <td class="table-cell">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>{{element.roomId ? getRoomInfo(element.roomId).name : '&mdash;'}}</span>
    </div>
    <div class="form-group" *ngIf="IsEdit" @animation>
      <div class="box-title">{{'Залы'|dic:'rooms'}}</div>
      <div class="row__field main-dropdown-box">
        <select class="select select__long" name="roomId" formControlName="roomId">
          <option *ngFor="let room of rooms" [value]="room.id">
            {{room.name}}
          </option>
        </select>
      </div>
    </div>
  </td>
  <td class="table-cell">
    <div class="desc field" *ngIf="!IsEdit" @animation>
      <span>{{element.comment}}</span>
    </div>
    <div class="form-group" *ngIf="IsEdit" @animation>
      <div class="box-title">Комментарий</div>
      <div class="row__field">
        <input class="form-control input__text input__text--long" name="comment" type="text" formControlName="comment" />
        <label class="field-validation-error" *ngIf="form.get('comment').touched && form.get('comment').invalid">Некорректное значение</label>
      </div>
    </div>
  </td>

  <td class="table-cell table-cell_ignore box-action">
    <button class="btn btn-clear active" (click)="edit()" *ngIf="!IsEdit" @animation>
      <span class="sp sp-pen"></span>
    </button>
    <a class="btn btn-link btn-small" (click)="cancel()" *ngIf="IsEdit" @animation>
      <span class="btn-primary__desc">Отмена</span>
    </a>
  </td>
  <td class="table-cell table-cell_ignore box-action">
    <button class="btn btn-clear active" (click)="remove(element)" *ngIf="!IsEdit" @animation>
      <span class="icon-cross"><span class="icon-cross__left"></span><span class="icon-cross__right"></span></span>
    </button>
    <button class="btn btn-primary btn-small" (click)="confirmSave(element)" *ngIf="IsEdit" @animation>
      <span class="btn-primary__desc">Сохранить</span>
    </button>
  </td>
</form>
