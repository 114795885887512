<header class="content__header">
  <div class="content__header_title">
    <span class="content__header-title-value content__header-title-value_default">
      Расписание
      <span class="gray content__header-title_text">{{Model.actualScheduleDate ? "актуально на " + Model.actualScheduleDate : ""}}</span>
    </span>
  </div>
</header>

<div class="content__body">
  <div class="schedule-container">
    <ng-container *ngIf="Model!=null">
      <ng-container *ngIf="componentReady == true">
        <div class="controls space-between-forExcelButton">
          <div class="wrap">
            <div class="switches">
              <input [(ngModel)]="showEmptyHours" (change)="updateShowEmpty(showEmptyHours)" type="checkbox"
                class="checkbox__slide" id="showEmptyHours">
              <label class="checkbox__slide_label" for="showEmptyHours">Отображать строки без занятий</label>
            </div>
            <div class="switches" *ngIf="scheduleMode === 'week'">
              <input [(ngModel)]="isInCopyMode" (change)="updateCopyMode(isInCopyMode)" type="checkbox"
                class="checkbox__slide" id="isInCopyMode">
              <label class="checkbox__slide_label" for="isInCopyMode">Режим копирования занятий
                <span class="key"> (Ctrl+D)</span>
              </label>
            </div>
            <div class="switches">
              <input [(ngModel)]="isDraftViewMode" (change)="updateDraftViewMode(isDraftViewMode)" type="checkbox"
                class="checkbox__slide" id="isDraftViewMode">
              <label class="checkbox__slide_label" for="isDraftViewMode">Показывать черновики</label>
            </div>
            <div class="switches" *ngIf="scheduleMode === 'week'">
              <input [(ngModel)]="fromCurrentDayMode" (change)="updateFromCurrentDayMode(fromCurrentDayMode)" type="checkbox"
                class="checkbox__slide" id="fromCurrentDayMode">
              <label class="checkbox__slide_label" for="fromCurrentDayMode">Прятать прошедшие дни в расписании при загрузке</label>
            </div>
            <div class="switches" *ngIf="scheduleMode === 'today'">
              <input [(ngModel)]="cellUnknownMode" (change)="updateCellUnknownMode(cellUnknownMode)" type="checkbox"
                     class="checkbox__slide" id="cellUnknownMode">
              <label class="checkbox__slide_label" for="cellUnknownMode">Скрыть колонку "не задано"</label>
            </div>
            <div class="switches" *ngIf="scheduleMode === 'today'">
              <input [(ngModel)]="backgroundColorBusyCellsMode" (change)="updateBackgroundColorBusyCellsMode(backgroundColorBusyCellsMode)" type="checkbox"
                     class="checkbox__slide" id="backgroundColorBusyCellsMode">
              <label class="checkbox__slide_label" for="backgroundColorBusyCellsMode">Пометить занятое время</label>
            </div>
          </div>
          <!-- ----- Экспорт в Эксель ----- -->
          <div class="wrap">
            <button class="btn btn-primary" (click)="exportInExcell()">
              <span class="btn-primary__desc">В Excel</span>
            </button>
          </div>
          <!-- ----- ----- ------ --- ----- -->
        </div>
        <hr class="separate-line">
        <div #tableStick>
          <div class="controls schedule-title__box" [class.table-header-stick]="tableHeader.stick">
            <h2 class="schedule-title" *ngIf="!tableHeader.stick && (scheduleMode === 'week' || scheduleMode === 'today')">{{actualTitleForSchedule | date: 'LLLL Y'|titlecase}}</h2>
            <h2 class="schedule-title" *ngIf="!tableHeader.stick && (scheduleMode === 'month' && datesPeriod)">{{datesPeriod.d2 | date: 'LLLL Y'|titlecase}}</h2>

            <div *ngIf="datesPeriod && scheduleMode === 'today';" class="dates">
              <a class="dates__link" (click)="moveDay(-1)">
                <span class="sp sp-arrow-small"></span>
              </a>
              <mat-form-field class="width-calendar">
                <input matInput [matDatepicker]="picker" (dateInput)="changeCalendar($event)" [(ngModel)]="pickDate">
                <mat-datepicker #picker (opened)="getMonthView()" (monthSelected)="getMonthView()">
                  <mat-datepicker-actions>
                    <button mat-raised-button color="primary" (click)="selectToday()">
                      Сегодня
                    </button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
              <label class="dates__value" (click)="picker.open()">{{ datesPeriod.d1 | date: 'd MMMM Y' }}</label>
              <a class="dates__link" (click)="moveDay(1)">
                <span class="sp sp-arrow-small"></span>
              </a>
            </div>

            <div *ngIf="datesPeriod && scheduleMode === 'week';" class="dates" id="week-period">
              <a class="dates__link" (click)="moveWeek(-1)">
                <span class="sp sp-arrow-small"></span>
              </a>
              <mat-form-field class="width-calendar">
                <input matInput [matDatepicker]="picker" (dateInput)="changeCalendar($event)" [(ngModel)]="pickDate">
                <mat-datepicker #picker (opened)="getMonthView()" (monthSelected)="getMonthView()">
                  <mat-datepicker-actions>
                    <button mat-raised-button color="primary" (click)="selectToday()">
                      Сегодня
                    </button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
              <label class="dates__value" (click)="picker.open()">{{ datesPeriod | dateInterval2string}}</label>
              <a class="dates__link" (click)="moveWeek(1)">
                <span class="sp sp-arrow-small"></span>
              </a>
            </div>

            <div *ngIf="datesPeriod && scheduleMode === 'month';" class="dates" id="month-period">
              <a class="dates__link" (click)="moveMonth(-1)">
                <span class="sp sp-arrow-small"></span>
              </a>
              <label class="dates__value">{{ datesPeriod.d2 | date: 'LLLL y' }}</label>
              <a class="dates__link" (click)="moveMonth(1)">
                <span class="sp sp-arrow-small"></span>
              </a>
            </div>

            <app-option-selector
              *ngIf="tableHeader.stick && window.innerWidth >= 700"
              class="me-3"
              [(ngModel)]="colorBy"
              (ngModelChange)="updateColorBy($event)"
              [options]="colorByModes"
              [opicker__text]="!tableHeader.stick">
            </app-option-selector>

            <div class="dropdown-stick" *ngIf="tableHeader.stick && window.innerWidth >= 992">
              <a *ngIf="tableHeader.stick" (click)="TapSwipe(-170)">
                <span class="sp sp-arrow-small"></span>
              </a>
              <div class="dropdown-wrap" #dropdown_wrap>
                <app-dropdown
                  class="dropdown-box first-upper" [items]="availableGroups" [alias]="'Направления'|dic:'groups'" [mat_select]="true" (update)="onFilterChange()">
                </app-dropdown>

                <app-dropdown class="dropdown-box"
                              [items]="availableCourses" [alias]="'Виды занятий'|dic:'courses'" [mat_select]="true" (update)="onFilterChange()">
                </app-dropdown>

                <app-dropdown
                  class="dropdown-box" [items]="availableTrainingCategories" [alias]="'Виды спорта'|dic:'trainingCategories'" [mat_select]="true" (update)="onFilterChange()">
                </app-dropdown>

                <app-dropdown class="dropdown-box"
                              [items]="availableCoaches" [alias]="'Тренеры'|dic:'trainers'" [mat_select]="true" (update)="onFilterChange()">
                </app-dropdown>

                <app-dropdown class="dropdown-box"
                              [items]="availableRooms" [alias]="'Залы'|dic:'rooms'" [mat_select]="true" (update)="onFilterChange()">
                </app-dropdown>

                <app-dropdown class="dropdown-box"
                              [items]="availableLevels" [alias]="'Уровни'|dic:'levels'" [mat_select]="true" (update)="onFilterChange()">
                </app-dropdown>
              </div>
              <a *ngIf="tableHeader.stick" (click)="TapSwipe(170)">
                <span class="sp sp-arrow-small"></span>
              </a>
            </div>

            <select class="box-link_wrap select" [(ngModel)]="scheduleMode" (ngModelChange)="changeScheduleModeHandler()" *ngIf="!tableHeader.stick">
              <option [value]="'today'">показать по дням</option>
              <option [value]="'week'">показать неделю</option>
              <option [value]="'month'">показать месяц</option>
            </select>
          </div>

          <div class="controls" *ngIf="!isEditing && !tableHeader.stick">

            <div class="opicker__wrapper">

              <app-option-selector
                class="me-3"
                [(ngModel)]="colorBy"
                (ngModelChange)="updateColorBy($event)"
                [options]="colorByModes"
                [opicker__text]="!tableHeader.stick">
              </app-option-selector>

              <div class="opicker" *ngIf="scheduleMode === 'today'">
                  <span class="opicker__text">В столбцах:</span>
                  <select class="box-link_wrap select me-2" [(ngModel)]="displayCategory" (ngModelChange)="updateDisplayCategoryMode()">
                    <option [value]="'room'">Залы</option>
                    <option [value]="'couches'">Тренеры</option>
                    <option [value]="'courses'">Виды занятий</option>
                  </select>

                  <select class="box-link_wrap select" [(ngModel)]="displayExtraDaysMode" (ngModelChange)="updateDisplayExtraDaysMode()">
                    <option [value]="1">Один день</option>
                    <option [value]="2">Два дня</option>
                    <option [value]="3">Три дня</option>
                    <option [value]="4">Четыре дня</option>
                    <option [value]="5">Пять дней</option>
                    <option [value]="6">Шесть дней</option>
                  </select>
              </div>
            </div>
          </div>
          <!--ФИЛЬТРЫ-->
          <div class="dropdown-wrap" *ngIf="!tableHeader.stick">
            <app-dropdown
              class="dropdown-box first-upper" [items]="availableGroups" [alias]="'Направления'|dic:'groups'" [mat_select]="true" (update)="onFilterChange()">
            </app-dropdown>

            <app-dropdown class="dropdown-box"
              [items]="availableCourses" [alias]="'Виды занятий'|dic:'courses'" [mat_select]="true" (update)="onFilterChange()">
            </app-dropdown>

            <app-dropdown
              class="dropdown-box" [items]="availableTrainingCategories" [alias]="'Виды спорта'|dic:'trainingCategories'" [mat_select]="true" (update)="onFilterChange()">
            </app-dropdown>

            <app-dropdown class="dropdown-box"
              [items]="availableCoaches" [alias]="'Тренеры'|dic:'trainers'" [mat_select]="true" (update)="onFilterChange()">
            </app-dropdown>

            <app-dropdown class="dropdown-box"
              [items]="availableRooms" [alias]="'Залы'|dic:'rooms'" [mat_select]="true" (update)="onFilterChange()">
            </app-dropdown>

            <app-dropdown class="dropdown-box"
              [items]="availableLevels" [alias]="'Уровни'|dic:'levels'" [mat_select]="true" (update)="onFilterChange()">
            </app-dropdown>
          </div>
        </div>

        <!--/-ФИЛЬТРЫ-->
        <ng-container *ngIf="!isEditing && scheduleMode === 'week'">
          <div class="table-responsive"
            (mouseenter)="onMouseOver($event)" [class.table-header-stick]="tableHeader.stick" [style.margin-top]="tableHeader.stick ? heightTableStick : '0'" #table>
            <div class="print">
              <div class="text">
                <div class="title" [style.marginBottom]="!qrCode ? '.4cm' : '0'">Расписание актуально на дату печати расписания {{nowDate | date:'dd.MM.yyyy'}}</div>
                <div class="title" *ngIf="qrCode">Здесь актуальное расписание на сегодня в режиме онлайн</div>
              </div>
              <div class="qrcode">
                <qrcode *ngIf="qrCode" [qrdata]="qrCode" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
            </div>

            <div class="table-schedule" [ngClass]="{'printed':isInCopyMode}">
              <div class="table-schedule__head">
                <div class="table-schedule__row">
                  <div class="table-cell"></div>
                  <div class="table-cell" *ngFor="let day of schedule.hours[0].days; let d=index" [class.active]="d === activeDayTab" (click)="changeDay(d, day.day)" [class.weekend]="day.day.getDay() === 6 || day.day.getDay() === 0">
                    <div class="headItem" [class.currentDay]="isCurrentDay(day.day)">
                      <span class="title">
                        {{ day.day | date: 'EEEEEE' | titlecase  }}
                      </span>
                      <span class="marker">
                        {{day.day |date: 'd'}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-schedule__body">
                <!-- Сначала выводим строку 00:00-00:05 -->
                <div class="table-schedule__row" *ngIf="(!schedule.nightTime.isEmptyHour) || showEmptyHours">
                  <div class="table-cell">
                    <div class="cell">{{ schedule.nightTime.hour }}</div>
                  </div>
                  <ng-container *ngFor="let day of schedule.nightTime.days; let i = index">
                    <div *ngIf="day.items.length == 0" (click)="onPlaceClick(null, day.day, '00:00')" class="table-cell" [ngStyle]="{backgroundColor: day.day.getDay() === 6 || day.day.getDay() === 0 ? '#F9EAEA' : ''}">
                      <div class="cell">{{ schedule.nightTime.hour }}</div>
                      <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : '00:00' : clubDownTime"></div>
                    </div>
                    <div *ngIf="day.items.length > 0" class="table-cell" [class.view]="i === activeDayTab" [class.place]="isDraftViewMode || !isOnlyDraftInCell(day.items)" [ngStyle]="{backgroundColor: day.day.getDay() === 6 || day.day.getDay() === 0 ? '#F9EAEA' : ''}">
                      <ng-container *ngFor="let item of classesSortOrderInHourseCell(day.items); let isLast=last">
                        <div app-class-item [isInCopyMode]="isInCopyMode" [showEdit]="isInCopyMode" [colorBy]="colorBy" [Model]="item"
                          *ngIf="isDraftViewMode || !isDraft(item)" (click)="onCardClick(item)"
                          (onClick)="onEditStart($event)" [last]="isLast" class="cell" [style.zIndex]="7-i+3"
                          [ngClass]="{'last':isLast}"></div>
                      </ng-container>
                      <div class="cell" (click)="onPlaceClick(null, day.day, '00:00')">{{ !isDraftViewMode && isOnlyDraftInCell(day.items) ? schedule.nightTime.hour : '' }}</div>
                      <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : '00:00' : clubDownTime"></div>
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngFor="let hour of schedule.hours">
                  <div class="table-schedule__row" *ngIf="(!hour.isEmptyHour) || showEmptyHours">
                    <div class="table-cell">
                      <div class="cell">{{ hour.hour }}</div>
                    </div>
                    <ng-container *ngFor="let day of hour.days; let i = index">
                      <div *ngIf="day.items.length == 0" (click)="onPlaceClick(null, day.day, hour.hour)" class="table-cell" [ngStyle]="{backgroundColor: day.day.getDay() === 6 || day.day.getDay() === 0 ? '#F9EAEA' : ''}">
                        <div class="cell">{{ hour.hour }}</div>
                        <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : hour.hour : clubDownTime"></div>
                      </div>

                      <div *ngIf="day.items.length > 0" class="table-cell" [class.view]="i === activeDayTab" [class.place]="isDraftViewMode || !isOnlyDraftInCell(day.items)" [ngStyle]="{backgroundColor: day.day.getDay() === 6 || day.day.getDay() === 0 ? '#F9EAEA' : ''}">
                        <ng-container *ngFor="let item of classesSortOrderInHourseCell(day.items); let isLast=last">
                          <div app-class-item [isInCopyMode]="isInCopyMode" [showEdit]="isInCopyMode" [colorBy]="colorBy" [Model]="item"
                            *ngIf="isDraftViewMode || !isDraft(item)" (click)="onCardClick(item)"
                            (onClick)="onEditStart($event)" [last]="isLast" class="cell" [style.zIndex]="7-i+3"
                            [ngClass]="{'last':isLast}"></div>
                        </ng-container>
                        <div class="cell" (click)="onPlaceClick(null, day.day, hour.hour)">{{ !isDraftViewMode && isOnlyDraftInCell(day.items) ? hour.hour : '' }}</div>
                        <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : hour.hour : clubDownTime"></div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <button class="btn btn-outline d-block d-md-none mt-4 mx-auto mx-md-0" (click)="onPlaceClick(null, schedule.hours[0].days[activeDayTab].day, '00:00')">Добавить занятие</button>
              </div>
            </div>
            <div class="tableSideLinkWrap"></div>
          </div>
        </ng-container>

        <ng-container *ngIf="scheduleMode === 'month'">
          <app-compact-schedule-mode
            [schedule]="compactSchedule"
            [colorBy]="colorBy"
            [isInCopyMode]="isInCopyMode"
            [isDraftViewMode]="isDraftViewMode"
            [clubDownTime]="clubDownTime"
            [tableStick]="tableStick"
            (stickHeader)="CheckHeaderStick($event)"
          ></app-compact-schedule-mode>
        </ng-container>

        <ng-container *ngIf="scheduleMode === 'today'">
          <app-day-schedule-mode
            [schedule]="schedule"
            [currentDay]="datesPeriod.d1"
            [colorBy]="colorBy"
            [isInCopyMode]="isInCopyMode"
            [displayCategoryInput]="displayCategory"
            [showEmptyHours]="showEmptyHours"
            [isDraftViewMode]="isDraftViewMode"
            [cellUnknownMode]="cellUnknownMode"
            [backgroundColorBusyCellsMode]="backgroundColorBusyCellsMode"
            [displayExtraDaysMode]="displayExtraDaysMode"
            [availableRooms]="availableRooms"
            [availableCoaches]="availableCoaches"
            [availableCourses]="availableCourses"
            [classesFilter]="classesFilter"
            [clubDownTime]="clubDownTime"
            [tableStick]="tableStick"
            (stickHeader)="CheckHeaderStick($event)"
          ></app-day-schedule-mode>
        </ng-container>

        <div class="row no-print mt-3">
          <div class="col-md-12 schedule-footnote">
            <p class="clue-text"><span class="letter newGroup">Н</span>Новый набор в группу</p>
            <p class="clue-text"><span class="letter draft">Ч</span>Черновик. Занятие сохранено, но недоступно для просмотра клиентами</p>
          </div>
          <hr class="separate-line">
          <div class="col-12 col-xs-12">
            <div class="box-title">
              {{Model.actualScheduleDate ? "Подтверждено " + Model.actualScheduleDate : "Не подтверждено"}}
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-6 col-md-4 mt-3" *ngIf="isEditAvailable">
            <button class="btn btn-primary" (click)="actualSchedule()">
              <span class="btn-primary__desc">Расписание актуально на сегодня</span>
            </button>
          </div>
          <div class="col-12 col-xs-12 col-sm-6 col-md-4 mt-3">
            <button class="btn btn-primary" (click)="goToClubDownTime()">
              <span class="btn-primary__desc">Нерабочее время</span>
            </button>
          </div>
          <div class="col-12 col-xs-12 col-sm-6 col-md-4 mt-3" *ngIf="isShowBtnAccounting">
            <button class="btn btn-primary" (click)="goToAccounting()">
              <span class="btn-primary__desc">Финансовый учет</span>
            </button>
          </div>
          <div class="col-12 col-xs-12">
            <div class="info-text">Отметьте, чтобы у занимающихся не было вопросов об актуальности расписания</div>
          </div>
        </div>
      </ng-container>

      <hr class="separate-line">
      <div class="global-actions">
        <button class="btn btn-link" (click)="startCancelAllClasses()">
          <span>Отменить все занятия</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #tmpExcellPopup let-settings="settings">
  <div class="tmpExcellPopupBody">
    <div class="tmpExcellPopupMess">Какие поля включить</div>
    <div class="switches">
      <input [(ngModel)]="exportFieldName" (change)="setDataToExport('exportFieldName')" type="checkbox"
        class="checkbox__slide" id="exportFieldName">
      <label class="checkbox__slide_label" for="exportFieldName">Название</label>
    </div>
    <div class="switches">
      <input [(ngModel)]="exportFieldDuration" (change)="setDataToExport('exportFieldDuration')" type="checkbox"
        class="checkbox__slide" id="exportFieldDuration">
      <label class="checkbox__slide_label" for="exportFieldDuration">Время и длительность занятия</label>
    </div>
    <div class="switches">
      <input [(ngModel)]="exportFieldZal" (change)="setDataToExport('exportFieldZal')" type="checkbox"
        class="checkbox__slide" id="exportFieldZal">
      <label class="checkbox__slide_label" for="exportFieldZal">Зал</label>
    </div>
    <div class="switches">
      <input [(ngModel)]="exportFieldComment" (change)="setDataToExport('exportFieldComment')" type="checkbox"
        class="checkbox__slide" id="exportFieldComment">
      <label class="checkbox__slide_label" for="exportFieldComment">Комментарий</label>
    </div>
    <div class="switches">
      <input [(ngModel)]="exportFieldTrener" (change)="setDataToExport('exportFieldTrener')" type="checkbox"
        class="checkbox__slide" id="exportFieldTrener">
      <label class="checkbox__slide_label" for="exportFieldTrener">Тренер</label>
    </div>
    <div class="switches">
      <input [(ngModel)]="exportFieldLevel" (change)="setDataToExport('exportFieldLevel')" type="checkbox"
        class="checkbox__slide" id="exportFieldLevel">
      <label class="checkbox__slide_label" for="exportFieldLevel">Уровень</label>
    </div>
    <div class="switches">
      <input [(ngModel)]="exportFieldZanatie" (change)="setDataToExport('exportFieldZanatie')" type="checkbox"
        class="checkbox__slide" id="exportFieldZanatie">
      <label class="checkbox__slide_label" for="exportFieldZanatie">Занятость занятия</label>
    </div>
    <div class="switches">
      <input [(ngModel)]="exportFieldColor" (change)="setDataToExport('exportFieldColor')" type="checkbox"
        class="checkbox__slide" id="exportFieldColor">
      <label class="checkbox__slide_label" for="exportFieldColor">Цвет вида занятия</label>
    </div>
  </div>
</ng-template>
