<div [style.margin-top]="tableHeader.stick ? heightTableStick : '0'" #table [class.table-header-stick]="tableHeader.stick">
  <div class="table-schedule" *ngFor="let week of schedule.days" [ngClass]="{'printed':isInCopyMode}">
    <div class="table-schedule__head">
      <div class="table-schedule__row" >
        <div class="table-cell" *ngFor="let day of week" (click)="onEditStartEmpty(null, day.day, '00:00')" [ngStyle]="{backgroundColor: day.day.getDay() === 6 || day.day.getDay() === 0 ? '#F9EAEA' : ''}">
          <div class="headItem" [class.currentDay]="isCurrentDay(day.day)">
            <span class="title">
              {{ day.day | date: 'EEEEEE' | titlecase  }}
            </span>
            <span class="marker">
              {{day.day |date: 'd'}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-schedule__body">
      <div class="table-schedule__row" >
        <div class="table-cell" *ngFor="let day of week" [ngStyle]="{backgroundColor: day.day.getDay() === 6 || day.day.getDay() === 0 ? '#F9EAEA' : ''}" (click)="onEditStartEmpty(null, day.day, '00:00')">
          <ng-container *ngFor="let classItem of classesSortOrderInHourseCell(day.items)">
            <div class="class-item" [class.showInSchedule]="isShowInSchedule(classItem)" *ngIf="isDraftViewMode || !isDraft(classItem.entityStatus)" [ngClass]="{'ghost':isDraft(classItem.entityStatus), 'clone':isVirtual(classItem.id)}" (click)="onEditStart(classItem)">
              <div class="bg" [style.backgroundColor]="colorise(classItem).bg" [style.borderColor]="colorise(classItem).borderColor"></div>
              <div class="class-item__title"[style.backgroundColor]="classItem.course?.color">{{classItem.course?.name}}</div>
              <div class="class-item__time" [style.backgroundColor]="classItem.course?.color">
                {{classItem.startTime}} - {{getEndTime(classItem) |date:'HH:mm'}}
              </div>
              <div class="class-item__info">
                <span class="class-item__coach" *ngIf="classItem.coach?.name" [style.backgroundColor]="classItem.coach?.color">
                  {{classItem.coach.name | initials}}
                </span>
                <span class="class-item__room" *ngIf="classItem.room?.name" [style.backgroundColor]="classItem.room?.color">
                  {{classItem.room.name | initials}}
                </span>

                <span class="class-item__capacity">
                  <ng-container *ngIf="classItem.maxCapacity === 0">{{classItem.numberOfVisits}}</ng-container>
                  <ng-container *ngIf="classItem.maxCapacity !== 0">{{classItem.numberOfVisits}}/{{classItem.maxCapacity}}</ng-container>
                  <ng-container *ngIf="isShowInSchedule(classItem)"> - ОТМЕНА</ng-container>
                </span>

                <div class="badge">
                  <span class="letter newGroup" *ngIf="classItem.isNewGroup"></span>
                  <span class="letter draft" *ngIf="isDraft(classItem.entityStatus)"></span>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : '00:00' : clubDownTime"></div>
        </div>
      </div>
    </div>
  </div>
</div>

