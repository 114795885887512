<div class="text-edit-wr">
    <div class="title-wrap">
        <div class="box-title">
            {{title}}
            <span class="box-edit" (click)="onBeginEdit()" *ngIf="!isReadonly">
              <span class="sp sp-download"></span>
            </span>
        </div>
        <div class="clue-text">
            Загрузите файл в формате CSV для проверки. 
            Файл должен содержать данные в виде таблицы. 
            Обязательный столбец «Имя» должен включать имя и фамилию, разделенные пробелом. 
            Также требуется наличие следующих столбцов: «Телефон», «Email», «Пол», «Комментарий». 
            Убедитесь, что данные корректны перед загрузкой.
        </div>
        <div class="row__save" [hidden]="!enableEdit" *ngIf="!isReadonly">
            <span class="row__save_save" (click)="onSave()">
              <span class="icon-check icon-check_color">
                  <span class="icon-check__left"></span>
                  <span class="icon-check__right"></span>
              </span>
              <span class="title">Проверить</span>
            </span>
            <span class="row__save_cancel" (click)="onCancel()">
              <span class="icon-cross icon-cross_color">
                  <span class="icon-cross__left"></span>
                  <span class="icon-cross__right"></span>
              </span>
              <span class="title">Отменить</span>
            </span>
        </div>
    </div>

    <div class="row__field" [hidden]="!enableEdit">
        <div class="field wysiwyg">
            <span *ngFor="let file of tempData" class="dark-gray">{{file.name}}</span>
        </div>
        <input type="file" class="fileInput" (change)="onSelectFile($event)" style="display:none" />
    </div>
</div>
<div class="" *ngIf="responseVisible">
    <div class="response-output">
      <div class="response-result">
        <div class="clue-text">Результаты проверки:</div>
        <ng-container *ngIf="serverResponse.result.item2 != ''; else noErrors">
          <strong class="error-text" style="word-wrap: break-word;">
            <span class="block-text">В файле имеются ошибки:</span>   
            <span class="block-text">{{ serverResponse.result.item2 }}</span>
            <span class="block-text">Исправьте ошибки, чтобы загрузить файл.</span>
          </strong>
        </ng-container>
        
        <ng-template #noErrors>
          <div>
            <strong class="success-text" style="word-wrap: break-word;">
              Файл загрузится без ошибок.
            </strong>
          </div>
          <div>
            <button class="btn btn-primary" (click)="onImport()">Импортировать файл</button>
          </div>
        </ng-template>
        <div>
          <div class="clue-text"> Количество клиентов: {{ serverResponse.result.item1.length }}</div>
        </div>
        <div >
          <button class="btn btn-primary" (click)="toggleAllClients()">
            {{ isAllClientsExpanded ? 'Свернуть список' : 'Посмотреть список клиентов' }}
          </button>
        </div>
      </div>
      
      
      <div class="listWrapper" *ngIf="isAllClientsExpanded">
        <div *ngFor="let client of serverResponse.result.item1">
          <ul>
            <li>
              <span class="label"><strong>Имя:</strong></span>
              <span class="textValue">{{ client.item1.firstName }}</span>
            </li>
            <li>
              <span class="label"><strong>Фамилия:</strong></span>
              <span class="textValue">{{ client.item1.lastName }}</span>
            </li>
            <li>
              <span class="label"><strong>Пол:</strong></span>
              <span class="textValue">{{ client.item1.gender }}</span>
            </li>
            <li>
              <span class="label"><strong>Телефон:</strong></span>
              <span class="textValue">{{ client.item1.phone }}</span>
            </li>
            <li>
              <span class="label"><strong>Email:</strong></span>
              <span class="textValue">{{ client.item1.email }}</span>
            </li>
            <li>
              <span class="label"><strong>Описание:</strong></span>
              <span class="textValue">{{ client.item1.description }}</span>
            </li>
          </ul>
          <strong *ngIf="client.item2 != ''" class="error-text">Ошибка: {{ client.item2 }}</strong>
          <hr />
        </div>
      </div>
    </div>
  
</div>
