import {DicService} from './../../common/services/dic.service';
import {ScheduleService} from 'app/components/schedule/services/schedule.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Injector,
  HostListener,
  OnDestroy,
  TemplateRef
} from '@angular/core';
import {Location} from '@angular/common';

import {Hotkey} from 'custom-modules/angular2-hotkeys';
import * as helpers from 'app/common/helpers.functions';
import * as _ from 'lodash';

import {AlertsService} from 'app/common/components/alerts/services/alerts.service';

import {TableListComponent} from 'app/common/table-list/table-list.component';
import {ITableHeader} from 'app/common/models/scroll-models.model';
import {IClassItemModel} from 'app/common/models/class-item.model';
import {EntityStatus} from 'app/common/models/entity-status.model';
import {ISchedule} from 'app/common/models/schedule.model';
import {FilterItem} from 'app/shared-components/dropdown/dropdown.component';
import * as Utility from '../../common/utility';
import {ApiV2Service} from "../../common/services/api-v2.service";
import {WidgetType} from "../../common/models/widgets.model";
import {environment} from "../../../environments/environment";
import * as moment from 'moment';
import {ScheduleHubService} from './services/schedule.hub.service';
import {ApiService} from '../../common/services/api.service';
import {ClubSetting} from '../../common/models/club-setting.model';
import {ClubDownTime} from '../../common/models/club-down-time.model';
import { Subscription } from 'rxjs';
import { ExcelService } from './../../common/services/excel.service';
import {CancelAllClassReq} from '../../common/models/cancel-all-class.model';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
  // tslint:disable-next-line:use-host-property-decorator
})
// TODO: сделать не через tableList, чтобы избавиться от проблем с типизацией
export class ScheduleComponent extends TableListComponent<ISchedule> implements OnInit, OnDestroy {
  public static readonly componentName: string = 'ScheduleComponent';

  public isBtnAccountingAvailable: boolean;

  private scheduleSignalRSubscription$?: Subscription;

  public Model: ISchedule;
  protected hkCloneToggle: Hotkey | Hotkey[];
  public componentReady: boolean;
  private itemRef;
  private schedule;
  public compactSchedule;
  private newTime;
  private newDate;
  public actualTitleForSchedule;
  public activeDayTab: number;
  public reserveActiveDayTab: number; //Для переназначения активного дня, при выборе определенного дня из выпадающего календаря или для запоминания при возврате из компактного режима.
  public days: string[];
  public mode: string;
  public tableHeader: ITableHeader;
  public tableWidth: number;
  public scrollPosition: number = null;
  public showEmptyHours = this.service.getShowEmpty();
  public isInCopyMode = this.service.getCopyMode();
  public isDraftViewMode = this.service.getDraftViewMode();
  public fromCurrentDayMode = this.service.getFromCurrentDayMode();
  public cellUnknownMode = this.service.getCellUnknownMode();
  public backgroundColorBusyCellsMode = this.service.getBackgroundColorBusyCellsMode();
  public colorBy = this.service.getColorBy();
  public datesPeriod = null;
  public scheduleMode: 'today' | 'week' | 'month' = this.service.getCompactScheduleMode();

  public pickDate: any;
  public subDate: any;

  public clubSettings: ClubSetting[] = [];
  public isShowBtnAccounting = false;

  /* Фильтры расписания */
  public availableGroups: FilterItem[] = [];
  public availableCourses: FilterItem[] = [];
  public availableCoaches: FilterItem[] = [];
  public availableRooms: FilterItem[] = [];
  public availableTrainingCategories: FilterItem[] = [];
  public availableLevels: FilterItem[] = [];

  public colorByModes = {
    coach: 'Тренеры',
    room: 'Залы',
    course: 'Виды занятий'
  };

  public modes = {
    day: 'День',
    week: 'Неделя',
    month: 'Месяц'
  };

  public qrCode: string;
  public nowDate = new Date();

  lastCheckedItem: IClassItemModel;

  @ViewChild('table') tableView: ElementRef;
  @ViewChild('tableBlock') tableBlock: ElementRef;
  @ViewChild('tableStick') tableStick: ElementRef;
  @ViewChild('dropdown_wrap') dropdownWrap: ElementRef;

  @ViewChild('picker') datePicker: any;

  public heightTableStick: any;

  public displayCategory: 'room' | 'couches' | 'courses' = this.service.getDisplayCategoryMode();
  public displayExtraDaysMode = this.service.getDisplayExtraDaysMode();
  public classesFilter: any[] = [];

  public clubDownTime: ClubDownTime[] = [];

  // ----- Переменные для экспорта
  public isShowPopUpExport: boolean = false;
  public exportFieldName = this.service.getExportField('exportFieldName');
  public exportFieldZal = this.service.getExportField('exportFieldZal');
  public exportFieldComment = this.service.getExportField('exportFieldComment');
  public exportFieldTrener = this.service.getExportField('exportFieldTrener');
  public exportFieldZanatie = this.service.getExportField('exportFieldZanatie');
  public exportFieldColor = this.service.getExportField('exportFieldColor');
  public exportFieldLevel = this.service.getExportField('exportFieldLevel');
  public exportFieldDuration = this.service.getExportField('exportFieldDuration');

  @ViewChild('tmpExcellPopup') tmpExcellPopup: TemplateRef<any>;

  constructor(
    injector: Injector,
    protected service: ScheduleService,
    protected alertsService: AlertsService,
    public location: Location,
    private dic: DicService,
    private apiV2Service: ApiV2Service,
    private scheduleHubService: ScheduleHubService,
    private excelService: ExcelService,
    private api: ApiService
  ) {
    super(injector, service);
    this.hkCloneToggle = this.hotkeysService.add(new Hotkey(['ctrl+d'], this.hkCloneTogglePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));

    const filters = this.service.getFilters();

    if (filters) {
      this.availableGroups = filters.groups || [];
      this.availableCourses = filters.courses || [];
      this.availableCoaches = filters.coaches || [];
      this.availableRooms = filters.rooms || [];
      this.availableTrainingCategories = filters.trainingCategories;
    }
  };

  @HostListener('window:scroll', ['$event']) onWindowScroll() {
    if(window.innerWidth <= 992) {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.stickTableHead(scrollPosition);
    }
  }

  ngOnDestroy(): any {
    this.scheduleSignalRSubscription$.unsubscribe();
    document.getElementById('app_container').removeAllListeners();
  }

  async ngOnInit(): Promise<any> {
    const app_container = document.getElementById('app_container');
    app_container.onscroll = () => {
      window['CoordinatesAppContainer'] = { x: app_container.scrollLeft, y: app_container.scrollTop };
    };

    this.clubSettings = await this.api.clubSettings.getByClub(this.contextService.getRoutingParams().clubId);
    if (this.clubSettings.find(x => x.alias === 'IsShowBtnAccounting'))
      this.isShowBtnAccounting = JSON.parse(this.clubSettings.find(x => x.alias === 'IsShowBtnAccounting')?.value) ?? false;

    this.clubDownTime = await this.apiV2Service.clubDownTime.getByClubId({ clubId: this.contextService.getRoutingParams().clubId, startAt: null, endAt: null });
    for (const c of this.clubDownTime) {
      c.startHoursAlias = Number(c.startHoursAlias.toString().substring(0, c.startHoursAlias.toString().length - 2) + '00');
    }

    this.dic.setData(this.route.snapshot.paramMap.get('clubId'));
    this.mode = 'day';

    this.tableHeader = {
      position: 0,
      positionBottom: 0,
      offset: 0,
      stick: false,
      leftScroll: 0,
    };

    this.days = [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота'
    ];

    this.service.controlState.baseDate = this.contextService.getRoutingParams().startDate;
    this.service.controlState.incrementRequestCount = true;
    if (!this.service.controlState.toDate) {
      this.changeCalendar(this.service.controlState.baseDate);
      this.pickDate = moment(this.service.controlState.baseDate, 'DD.MM.YYYY');
    }
    this.setDatesForUpdateModel();

    this.scheduleHubService.start(this.contextService.getRoutingParams().clubId);
    this.startSignalRListener();

    this.newDate = this.service.controlState.baseDate;
    this.newTime = '00.00';
    this.route.queryParams.subscribe(params => {
      if (params.scroll) {
        this.scrollPosition = params.scroll;
        this.reserveActiveDayTab = params.activeTab;
        this.location.replaceState(this.location.path().slice(0, this.location.path().indexOf('?')));
      }
    });

    //Для прилипания шапки таблицы при прокрутке, когда app_container на 100vh и нельзя обратиться к прокрутке window
    if(window.innerWidth > 992) {
      const appContainer = document.querySelector('.app_container');
      appContainer.addEventListener('scroll', () => {
        this.stickTableHead(appContainer.scrollTop);
      });
    }

    super.ngOnInit();
  }

  private startSignalRListener() {
    this.scheduleSignalRSubscription$ = this.scheduleHubService.scheduleUpdateEvent
      .subscribe((res: any) => setTimeout(async () => {
        const _clubId = res;
        if (this.contextService.getRoutingParams().clubId === _clubId) {
          this.clubDownTime = await this.apiV2Service.clubDownTime.getByClubId({ clubId: this.contextService.getRoutingParams().clubId, startAt: null, endAt: null });
          for (const c of this.clubDownTime) {
            c.startHoursAlias = Number(c.startHoursAlias.toString().substring(0, c.startHoursAlias.toString().length - 2) + '00');
          }
          super.ngOnInit();
        }
      }, 1000));
  }

  public CheckHeaderStick(stick: boolean) {
    this.tableHeader.stick = stick;
    if (!this.tableHeader.stick) {
      // const marginTop = parseInt(getComputedStyle(document.getElementsByClassName('controls schedule-title__box')[0]).marginTop);
      // const marginBottom = parseInt(getComputedStyle(document.getElementsByClassName('dropdown-wrap')[0]).marginBottom);
      // this.heightTableStick = String(this.tableStick.nativeElement.offsetHeight + marginBottom + marginTop) + 'px';
    }
  }

  public stickTableHead(pageOffset: number) {
    this.tableHeader.position = this.tableView.nativeElement.offsetTop;
    this.tableHeader.positionBottom = this.tableHeader.position + this.tableView.nativeElement.offsetHeight;

    let pageHeaderHeight = window.innerWidth <= 992 ? 0 : 91;

    if(pageOffset + pageHeaderHeight >= this.tableHeader.position && pageOffset <= this.tableHeader.positionBottom - 100) {
      this.tableHeader.stick = true;
    } else {
      this.tableHeader.stick = false;
      const marginTop = parseInt(getComputedStyle(document.getElementsByClassName('controls schedule-title__box')[0]).marginTop);
      const marginBottom = parseInt(getComputedStyle(document.getElementsByClassName('dropdown-wrap')[0]).marginBottom);
      this.heightTableStick = String(this.tableStick.nativeElement.offsetHeight + marginBottom + marginTop) + 'px';
    }
  }

  public TapSwipe (direction: number) {
    this.dropdownWrap.nativeElement.scrollBy({top: 0, left: direction, behavior: 'smooth'})
  }

  setDatesForUpdateModel() {
    const setControlStateToDate = ():any => {
      const current = helpers.str2date(this.service.controlState.baseDate);
      let base = helpers.addDays(current, 7);
      return helpers.date2str(base);
    }

    if(this.scheduleMode === 'month') {
      this.setDatesForCompactMode();
      // this.router.navigate([`../${this.service.controlState.baseDate}`], {relativeTo: this.route});
      return;
    }

    if (this.scheduleMode === 'today') {
      this.service.controlState.toDate = setControlStateToDate();
      return;
    }

    //Если галочка вкл то отсчет недели от вчерашнего дня + неделя (в getItemList() передаем также toDate),
    //иначе установим только baseDate c пн и метод getItemList() из schedule.service вернёт ровно неделю с пн
    if(this.fromCurrentDayMode) {
      this.service.controlState.baseDate = new Date(this.cityService.getTimestampClub() - 86400000).toLocaleDateString('ru');
      this.router.navigate([`../${this.service.controlState.baseDate}`], {relativeTo: this.route});
      this.service.controlState.toDate = setControlStateToDate();
    } else {
      const current = helpers.str2date(this.service.controlState.baseDate);
      const base = helpers.addDays(current, -(current.getDay()) + 1);

      this.service.controlState.baseDate = helpers.date2str(base);
      this.service.controlState.toDate = null;
    }
  }

  public hkCloneTogglePress(that: this) {
    return (event: KeyboardEvent) => {
      that.isInCopyMode = !that.isInCopyMode;
      that.updateCopyMode(that.isInCopyMode);
      event.preventDefault();
      return true;
    };
  }

  protected hkAddPress(that: this) {
    return () => {
      that.newDate = this.service.controlState.baseDate;
      that.newTime = '00.00';
      that.onEditStart(null);
      return true;
    };
  }

  onCardClick(item: IClassItemModel): void {
    if (this.isInCopyMode) {
      this.lastCheckedItem && (this.lastCheckedItem.isChecked = false);
      this.itemRef = _.cloneDeep(item);
      this.lastCheckedItem = item;
      this.lastCheckedItem.isChecked = true;
    } else {
      this.onEditStart(item);
    }
  }

  /**
   * Инициализирует сохранённую копию модели занятия выбранным временем и датой
   * @param date
   * @param time
   */
  private modelCopyConvert(date: Date, time: string): IClassItemModel {
    const i = _.cloneDeep(this.itemRef);
    i.startDate = helpers.date2str(date);
    i.startTime = `${time.substr(0, 2)}${i.startTime.substr(2, 3)}`;
    i.id = null;
    i.prototypeId = null;
    i.notifyBookedUsers = false;
    i.userIds = [];
    return i;
  }

  onPlaceClick(item?: IClassItemModel, date?: Date, time?: string): void {
    if (this.isInCopyMode) {
      if (this.itemRef) {
        if(time && time === "00:00") time = this.itemRef.startTime;

        this.service.save(this.modelCopyConvert(date, time))
          .then(() => {
            this.updateModel();
          });
      } else {
        this.hkCloneToggle = this.hotkeysService.add(new Hotkey(['ctrl+d'], this.hkCloneTogglePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));

        this.alertsService.showConfirm({
          header: 'Не выбрано занятие для копирования.',
          message: `1. Нажмите на занятие. <br>\
            2. Кликайте на пустые места в ячейках расписания, чтобы скопировать в них это занятие.`,
          buttons: [{
            title: 'Понятно'
          }]
        });
      }
    } else {
      this.onEditStartE(item, date, time);
    }
  }

  public isDraft(item: IClassItemModel): boolean {
    return item.entityStatus === EntityStatus.draft;
  }

  public isOnlyDraftInCell(items: Array<IClassItemModel>) {
    return items.every(i => this.isDraft(i));
  }

  onEditStartE(item, date: Date, time: string): void {
    this.newDate = helpers.date2str(date);
    this.newTime = time.replace(':', '.');
    this.onEditStart(item);
  }

  onEditStart(event: IClassItemModel) {
    let scrollPosition;
    if(window.innerWidth <= 992) {
      scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    } else {
      scrollPosition = document.querySelector('.app_container').scrollTop;
    }

    if (event != null) {
      if (event.id === '00000000000000000000000000000000') {
        this.router.navigate([`virtual/${event.prototypeId}/${event.startDate}`], {
          relativeTo: this.route,
          queryParams: {scroll: scrollPosition, activeTab: this.activeDayTab}
        });
      } else {
        this.router.navigate([`${event.id}`], {relativeTo: this.route, queryParams: {scroll: scrollPosition, activeTab: this.activeDayTab}});
      }
    } else if (this.isEditAvailable) {
      this.router.navigate([`create/${this.newDate}/${this.newTime}`], {
        relativeTo: this.route,
        queryParams: {scroll: scrollPosition, activeTab: this.activeDayTab}
      });
    }
    return true;
  }

  setWeek(dir: number): void {
    const current = helpers.str2date(this.service.controlState.baseDate);
    let base = helpers.addDays(current, -(current.getDay()) + 1);
    base = helpers.addDays(base, 7 * dir);
    this.service.controlState.baseDate = helpers.date2str(base);
  }

  moveWeek(dir: number): void {
    this.service.controlState.toDate = null;
    this.reserveActiveDayTab = undefined;
    this.setWeek(dir);
    this.router.navigate([`../${this.service.controlState.baseDate}`], {relativeTo: this.route});
    this.scrollPosition = this.tableStick.nativeElement.offsetTop - 60;
    this.updateModel();
  }

  moveMonth(dir: number): void {
    let routeParamsStartDate = helpers.str2date(this.contextService.getRoutingParams().startDate);
    let month = routeParamsStartDate.getMonth()+dir;
    this.setDatesForCompactMode(month);
    this.scrollPosition = this.tableStick.nativeElement.offsetTop - 60;
    this.updateModel();
  }

  moveDay(dir: number): void {
    const current = helpers.str2date(this.service.controlState.baseDate);
    const base = moment(current).add(dir, 'day').toDate();
    this.service.controlState.baseDate = helpers.date2str(base);
    this.service.controlState.toDate = helpers.date2str(moment(base).add(1, 'week').toDate());
    this.service.setMemoryDate(this.service.controlState.baseDate);
    this.router.navigate([`../${this.service.controlState.baseDate}`], {relativeTo: this.route});
    this.scrollPosition = this.tableStick.nativeElement.offsetTop - 60;
    this.updateModel();
  }

  getMonthView() {
    setTimeout(() => {
      this.subDate && this.subDate.unsubscribe()
      this.subDate = this.datePicker._componentRef?.instance._calendar.monthView.selectedChange.subscribe((res: any) => {
        this.datePicker.select(res);
        this.datePicker.close();
      })
    })
  }
  selectToday() {
    this.datePicker.select(moment());
    this.datePicker.close();
  }

  public async changeCalendar(event: any) {
    let selectedDate = typeof event === 'string' ? event : event.value.format('YYYY-MM-DD').split('-').reverse().join('.');
    selectedDate = helpers.str2date(selectedDate);
    if (this.scheduleMode === 'week') {
      this.service.controlState.toDate = null;
      this.reserveActiveDayTab = undefined;
      const weekDay = selectedDate.getDay();
      weekDay === 0 ? this.reserveActiveDayTab = 6 : this.reserveActiveDayTab = weekDay - 1;
      let diff: any;
      switch (weekDay) {
        case 0:
          diff = 6;
          break;
        case 1:
          diff = 0;
          break;
        default: diff = weekDay - 1;
      }
      selectedDate = helpers.addDays(selectedDate, -diff);
    }
    this.service.controlState.baseDate = helpers.date2str(selectedDate);
    if (this.scheduleMode === 'today') {
      this.service.controlState.toDate = helpers.date2str(moment(selectedDate).add(1, 'week').toDate());
    }
    this.service.setMemoryDate(this.service.controlState.baseDate);
    this.router.navigate([`../${this.service.controlState.baseDate}`], {relativeTo: this.route});
    this.updateModel();
  }

  updateShowEmpty(event) {
    this.service.setShowEmpty(event);
  }

  updateCopyMode(event) {
    this.service.setCopyMode(event);
  }

  updateDraftViewMode(event) {
    this.service.setDraftViewMode(event);
  }

  updateFromCurrentDayMode(event) {
    this.service.setFromCurrentDayMode(event);
    this.reserveActiveDayTab = undefined;
    this.setDatesForUpdateModel();
    this.router.navigate([`../${this.service.controlState.baseDate}`], {relativeTo: this.route});
    this.updateModel();
  }

  updateCellUnknownMode(event) {
    this.service.setCellUnknownMode(event);
  }

  updateBackgroundColorBusyCellsMode(event) {
    this.service.setBackgroundColorBusyCellsMode(event);
  }

  updateColorBy(event) {
    this.service.setColorBy(event);
  }

  updateDisplayCategoryMode() { this.service.setDisplayCategoryMode(this.displayCategory); }
  updateDisplayExtraDaysMode() { this.service.setDisplayExtraDaysMode(this.displayExtraDaysMode); }

  onModelLoadCallback() {
    const baseDateStr = this.service.controlState.baseDate;
    const baseDate = helpers.str2date(baseDateStr);

    this.datesPeriod = {d1: baseDate, d2: helpers.addDays(baseDate, 6)};
    this.actualTitleForSchedule = baseDate;

    this.scheduleMode === 'month' ? this.initCompactSchedule(baseDate, this.Model.classes) : this.initWeekSchedule(baseDate, this.Model.classes);

    this.service.getOtherModels().then(() => {
      this.componentReady = true;
      setTimeout(() => {
        if (this.scrollPosition) {
          window.innerWidth >= 992 ?
            document.querySelector('.app_container').scrollTop = this.scrollPosition :
            document.querySelector('.app_container').scrollTop = document.documentElement.scrollTop = this.scrollPosition + 60;
          this.scrollPosition = null;
        }

        this.activeDayTab = this.reserveActiveDayTab;
        this.getFilters();
        this.mapClassesToTimetable();
      }, 1);
    });
  }

  initCompactSchedule(baseDate: any, modelClasses: Array<IClassItemModel>) {
    this.compactSchedule = {
      days: []
    };

    const numberDays = helpers.diffBetween2Dates(this.service.controlState.baseDate, this.service.controlState.toDate);


    let days = [];
      for (const day of helpers.createRange(0, numberDays)) {
        //Добавляем свою дату каждому дню периода
        const cdate = helpers.addDays(baseDate, day);

        //Добавляем дни, сначала в наш пустой массив days, чтобы потом разбить его по [7 дней] и добавить в this.compactSchedule.days
        days.push({
          day: cdate,
          items: []
        });
      }

      for (const item of modelClasses.filter(i => i.classStatus === 'available' || i.isShowInSchedule)) {
        //Заполняем занятиями день, соответствующий дню начала занятия
        days.find(d => d.day.setHours(0,0,0,0) === helpers.str2date(item.startDate).setHours(0,0,0,0)).items.push(item);
      }

      for(let i=0; i<numberDays/7; i++) {
        let current = 7*i;
        this.compactSchedule.days.push(days.slice(current, current+7));
      }
  }

  initWeekSchedule(baseDate: any, modelClasses: Array<IClassItemModel>) {
    const addDaysInHour = (isNightTime: boolean, hour: number) => {
      //Заполняем часы днями
      for (const day of helpers.createRange(0, 6)) {
        const cdate = helpers.addDays(baseDate, day);

        //В ночное время добавим дни 1 раз, затем в каждый час таблицы
        isNightTime ?
          this.schedule.nightTime.days.push({
            day: cdate,
            items: []
          }) :
          this.schedule.hours[hour].days.push({
            day: cdate,
            items: []
          });
      }
    }

    this.schedule = {
      nightTime: {
        hour: '00:00 - 05:00',
        days: [],
        isEmptyHour: true
      },
      hours: []
    };

    addDaysInHour(true, null);

    //Заполняем таблицу часами
    for (const hour of helpers.createRange(6, 23)) {
      this.schedule.hours.push({
        hour: helpers.format2digits(hour) + ':00',
        days: [],
        isEmptyHour: true
      });

      //Отнимаем 6, т.к. первые 6 часов находятся в nightTime (здесь hour при заполнении будет просто как индекс)
      addDaysInHour(false, hour - 6);
    }

    //Индекс дня выбранной нед, кот соответствует сегодняшнему, либо "пн", если мы на другой неделе
    if(!this.reserveActiveDayTab) {
      const activeDayTabIndex = this.schedule.hours[0].days.findIndex(d => d.day.setHours(0,0,0,0) === new Date().setHours(0,0,0,0));
      activeDayTabIndex !== -1 ? this.activeDayTab = activeDayTabIndex  : this.activeDayTab = 0;
    } else {
      this.activeDayTab = +this.reserveActiveDayTab;
    }

    for (const item of modelClasses.filter(i => i.classStatus === 'available' || i.isShowInSchedule)) {
      // Номер часа в таблице
      const h = parseInt(item.startTime.substr(0, 2), 10);
      // Номер дня в таблице
      const d = new Date(helpers.str2date(item.startDate).getTime() - baseDate.getTime()).getUTCDate() - 1;

      // Заполняем дни часа занятиями
      if (d < 7 && d >= 0) {
        if(h < 6) {
          this.schedule.nightTime.days[d].items.push(item);
          this.schedule.nightTime.isEmptyHour = false;
        } else {
          this.schedule.hours[h - 6].days[d].items.push(item);
          this.schedule.hours[h - 6].isEmptyHour = false;
        }
      }
    }
    setTimeout(() => {
      if (window['CoordinatesAppContainer']) {
        document.getElementById('app_container').scroll(window['CoordinatesAppContainer'].x, window['CoordinatesAppContainer'].y);
      }
    }, 1);
  }

  actualSchedule() {
    this.service.actualSchedule()
      .then(() => this.Model.actualScheduleDate = new Date().toLocaleDateString('ru'));
  }

  private getFilters() {
    this.availableRooms = Utility
      .getUniqueItems(this.Model.classes, 'room')
      .map(item => {
        item.isEnabled = this.availableRooms.some(room => room.isEnabled && room.id === item.id);
        return item;
      })
      .sort(Utility.filterItemComparer);

    this.availableGroups = Utility
      .getUniqueItems(this.Model.classes, 'group')
      .map(item => {
        item.isEnabled = this.availableGroups.some(group => group.isEnabled && group.name === item.name);
        return item;
      })
      .sort(Utility.filterItemComparer);

    this.availableCourses = Utility
      .getUniqueItems(this.Model.classes, 'course')
      .map(item => {
        item.isEnabled = this.availableCourses.some(course => course.isEnabled && course.id === item.id);
        return item;
      })
      .sort(Utility.filterItemComparer);

    this.availableCoaches = Utility
      .getUniqueItems(this.Model.classes, 'coach')
      .map(item => {
        item.isEnabled = this.availableCoaches
          .some(coach => coach.isEnabled && coach.id === item.id);
        return item;
      })
      .sort(Utility.filterItemComparer);

    this.availableTrainingCategories = Utility
      .getUniqueItems(this.Model.classes, 'trainingCategories')
      .map(item => {
        item.isEnabled = this.availableTrainingCategories
          .some(category => category.isEnabled && category.id === item.id);
        return item;
      })
      .sort(Utility.filterItemComparer);

    this.availableLevels = Utility
      .getUniqueLevels(this.Model.classes)
      .map(item => {
        item.isEnabled = this.availableLevels
          .some(level => level.isEnabled && level.id === item.id);
        return item;
      })
      .sort(Utility.filterItemComparer);
  }

  onFilterChange() {
    this.mapClassesToTimetable();

    this.service.setFilters({
      groups: this.availableGroups.filter(x => x.isEnabled),
      courses: this.availableCourses.filter(x => x.isEnabled),
      couches: this.availableCoaches.filter(x => x.isEnabled),
      rooms: this.availableRooms.filter(x => x.isEnabled),
      trainingCategories: this.availableTrainingCategories.filter(x => x.isEnabled),
      levels: this.availableLevels.filter(x => x.isEnabled)
    });
  }

  private mapClassesToTimetable() {
    const classes = Utility.filterSchedule(
      this.Model.classes,
      this.availableGroups,
      this.availableCourses,
      this.availableCoaches,
      this.availableRooms,
      this.availableTrainingCategories,
      this.availableLevels
    );

    const baseDateStr = this.service.controlState.baseDate;
    const baseDate = helpers.str2date(baseDateStr);
    this.datesPeriod = {d1: baseDate, d2: helpers.addDays(baseDate, 6)};

    this.classesFilter = classes;

    if (this.scheduleMode === 'month') {
      this.initCompactSchedule(baseDate, classes)
    } else if (this.scheduleMode === 'week') { this.initWeekSchedule(baseDate, classes); }
  }

  setDatesForCompactMode(month?: any) {
      const routeParamsStartDate = helpers.str2date(this.contextService.getRoutingParams().startDate);
      let baseDate = helpers.str2date(this.service.controlState.baseDate);
      let lastDay = moment(baseDate).add(1, 'week').toDate();
      if (this.scheduleMode === 'month') {
        // Есть ли сегодняшнее число на выбранной неделе?
        const selectWeekHasToday = moment().diff(baseDate, 'days') >= 0 && moment().diff(baseDate, 'days') <= 6;
        // Сначала проверим, если startDate из route указывает на 1е число мес,
        // значит мы его туда уже передавали, используя этот метод и берем его, иначе перелистнет на мес назад при обновлении или смене режима
        if (routeParamsStartDate.getDate() === 1) {
          month ? baseDate = new Date(routeParamsStartDate.getFullYear(), month) : baseDate = new Date(routeParamsStartDate.getFullYear(), routeParamsStartDate.getMonth());
        } else if (selectWeekHasToday && window.innerWidth > 768) {
          // Получаем 1е число месяца сегодняшней даты, если в настольном виде на неделе есть сегодняшнее число.
          // В мобильном выбираем активными табами и при выборе 'Активный' таб и есть baseDate.
          baseDate = new Date(new Date().getFullYear(), new Date().getMonth());
        } else {
          // Получаем 1е число месяца начальной даты
          baseDate = new Date(baseDate.getFullYear(), baseDate.getMonth());
        }
        // Затем первое число мес, след за текущим
        lastDay = new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 1);
        this.router.navigate([`../${helpers.date2str(baseDate)}`], {relativeTo: this.route});
        lastDay.getDay() !== 0 ? lastDay = helpers.addDays(lastDay, 7 - lastDay.getDay() + 1) : lastDay = helpers.addDays(lastDay, 1);
        const baseDay = baseDate.getDay();
        if (baseDay !== 1) {
          baseDay !== 0 ? baseDate = helpers.addDays(baseDate,  - (baseDay - 1)) : baseDate = helpers.addDays(baseDate,  - 6);
        }
      }
      this.service.controlState.baseDate = helpers.date2str(baseDate);
      this.service.controlState.toDate = helpers.date2str(lastDay);
  }

  changeScheduleModeHandler() {
    this.service.setCompactScheduleMode(this.scheduleMode);
    switch (this.scheduleMode) {
      case 'today':
        this.service.controlState.baseDate = this.service.getMemoryDate();
        const _d = moment(`${this.service.controlState.baseDate.split('.')[2]}.${this.service.controlState.baseDate.split('.')[1]}.${this.service.controlState.baseDate.split('.')[0]}`);
        if (_d < moment()) {
          this.changeCalendar(moment().format('DD.MM.YYYY'));
          this.pickDate = moment();
        }
        this.pickDate = moment(this.service.controlState.baseDate, 'DD.MM.YYYY');
        this.setDatesForCompactMode();
        this.updateModel();
        break;
      case 'week':
        this.service.controlState.baseDate = this.service.getMemoryDate();
        this.router.navigate([`../${this.service.controlState.baseDate}`], {relativeTo: this.route});
        this.pickDate = moment(this.service.controlState.baseDate, 'DD.MM.YYYY');
        this.setDatesForUpdateModel();
        this.updateModel();
        break;
      case 'month':
        this.service.setMemoryDate(this.service.controlState.baseDate);
        this.reserveActiveDayTab = this.activeDayTab;
        this.setDatesForCompactMode();
        this.updateModel();
        break;
    }
  }

  async printSchedule() {
    const tableHeight = this.tableView.nativeElement.clientHeight;
    document.body.style.height = `${tableHeight}px`;
    setTimeout(() => {
      document.body.style.height = `100%`;
    }, 500)
    const ctx = this.contextService.getRoutingParams();
    const wgs = await this.apiV2Service.widgets.getByClub(ctx.clubId);
    const wg = wgs.find(w => w.widgetType == WidgetType.schedule && w.useForVk);
    if (typeof wg != "undefined") {
      if (wg.id)
        this.qrCode = environment.widgetsHost + '/schedule?w=' + wg.id;
    }
    setTimeout(() => window.print())
  }

  startCancelAllClasses() {
    this.alertsService.showConfirm({
      header: 'Вы уверены?',
      message: 'Применить изменения ко всему расписанию или к занятиям по выбранным фильтрам?',
      buttons: [{
        title: 'Все',
        callback: async () => {
          await this.cancelAllClasses(false);
          this.close();
        },
      }, {
        title: 'По выбранным фильтрам',
        callback: async () => {
          await this.cancelAllClasses(true);
          this.close();
        },
      }, {
        title: 'Отмена',
        callback: () => {
          this.close();
        }
      }]
    });
  }

  private async cancelAllClasses(isFilters: boolean) {
    const req = new CancelAllClassReq();
    req.isFilters = isFilters;
    req.baseDate = moment(new Date(Date.UTC(this.datesPeriod.d1.getFullYear(), this.datesPeriod.d1.getMonth(), this.datesPeriod.d1.getDate()))).format('DD.MM.YYYY');
    if (isFilters) {
      req.groupIds = this.availableGroups.filter(f => f.isEnabled).map(f => f.id);
      req.courseIds = this.availableCourses.filter(f => f.isEnabled).map(f => f.id);
      req.coachIds = this.availableCoaches.filter(f => f.isEnabled).map(f => f.id);
      req.roomIds = this.availableRooms.filter(f => f.isEnabled).map(f => f.id);
      req.trainingCategoryIds = this.availableTrainingCategories.filter(f => f.isEnabled).map(f => f.id);
      req.levels = this.availableLevels.filter(f => f.isEnabled).map(f => f.name);
    }

    let message = `Отменить <strong>все</strong> занятия с ${req.baseDate}`;
    if (req.isFilters) {
      const filters: any = [];
      if (req.courseIds.length) { filters.push('вид занятий'); }
      if (req.coachIds.length) { filters.push('тренеры'); }
      if (req.roomIds.length) { filters.push('залы'); }
      if (req.groupIds.length) { filters.push('направления'); }
      if (req.levels.length) { filters.push('уровни'); }
      if (req.trainingCategoryIds.length) { filters.push('виды спорта'); }
      if (filters.length) { message += ` по выбранным фильтрам:<br> ${filters.join(',')}` } else { req.isFilters = false; }
    }
    message += '?';

    this.alertsService.showConfirm({
      header: 'Вы уверены?',
      message: message,
      buttons: [{
        title: 'Да',
        callback: async () => {
          await this.service.cancelAllClass(req);
          this.ngOnInit();
          this.close();
        },
      }, {
        title: 'Отмена',
        callback: async () => {
          this.close();
        },
      }]
    });
  }

  public isCurrentDay(day: any) {
    return day.setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
  }

  public changeDay(dayIdx: number, date?: any) {
    if(window.innerWidth <= 768) {
      this.activeDayTab = dayIdx;
      this.service.controlState.baseDate = helpers.date2str(date);
    }
  }

  public classesSortOrderInHourseCell(dayItems: Array<IClassItemModel>) {
    return dayItems.sort((a, b) => a.startTime.localeCompare(b.startTime) || a.room?.name?.localeCompare(b.room?.name) || 1);
  }

  goToAccounting() {
    return this.router.navigate([`../../accounting`], { relativeTo: this.route });
  }

  goToClubDownTime() {
    return this.router.navigate([`../../downtime`], { relativeTo: this.route });
  }

  // ----- Экспорт в эксель
  exportInExcell() {
    let dayTitle: any = [];
    let dataTable: any = [];

    let categoryMode = this.service.getDisplayCategoryMode();
    let categoryFilters = [];
    switch (categoryMode) {
      case 'couches':
        categoryFilters = this.availableCoaches;
        break;
      case 'room':
        categoryFilters = this.availableRooms;
        break;
      case 'courses':
        categoryFilters = this.availableCourses;
        break;
      default: break;
    }
    // Столбец не задано
    if (categoryFilters.find(i => i.name === 'Не задано')) {
      categoryFilters.pop();
    }
    if (!this.cellUnknownMode) {
      categoryFilters.push({
        'color': '#000',
        'id': '000000000',
        'isEnabled': false,
        'name': 'Не задано'
      });
    }
    // Settings
    let settings = {
      'mode' : this.scheduleMode,
      'categoryMode' : categoryMode,
      'categoryFilters' : categoryFilters,
      'exportFieldName' : this.exportFieldName,
      'exportFieldZal' : this.exportFieldZal,
      'exportFieldComment' : this.exportFieldComment,
      'exportFieldTrener' : this.exportFieldTrener,
      'exportFieldZanatie' : this.exportFieldZanatie,
      'exportFieldColor' : this.exportFieldColor,
      'exportFieldLevel' : this.exportFieldLevel,
      'exportFieldDuration' : this.exportFieldDuration
    };

    // if (!(this.exportFieldDuration || this.exportFieldName || this.exportFieldZal || this.exportFieldComment || this.exportFieldTrener || this.exportFieldZanatie || this.exportFieldColor || this.exportFieldLevel)) {
    //   this.alertsService.showConfirm({
    //     header: 'Не заполнено ни одно поле!',
    //     buttons: [{
    //       title: 'Ok'
    //     }]
    //   });
    //   return;
    // }

    let rows = [];

    if (this.scheduleMode === "week") {
      // Headers
      dayTitle.push('Время');
      let _ = this.schedule.hours[0].days;
      _.forEach(element => {
        let day = new Date(element.day);
        let nameD = this.days[day.getDay()];
        let dateD = day.getDate();
        dayTitle.push(nameD + ' ' + dateD);
      });
      // Data
      // Night hours
      let days = this.schedule.nightTime.days;
      let nameH = this.schedule.nightTime.hour;
      let data: any = [];
      let data1: any = [];
      if (this.showEmptyHours)
      {
        data1.push(nameH);
        days.forEach(d => {
          if (d.items && d.items.length > 0)
          {
            this.formatData(d, data);
          }
          else {
            data.push('');
          }
          data1.push(data);
          data = [];
        });
        dataTable.push(data1);
        data1 = [];
      }
      else
      {
        if (!this.schedule.nightTime.isEmptyHour) {
          data1.push(nameH);
          days.forEach(d => {
            if (d.items && d.items.length > 0)
            {
              this.formatData(d, data);
            }
            else {
              data.push('');
            }
            data1.push(data);
            data = [];
          });
          dataTable.push(data1);
          data1 = [];
        }
      }
      // Days hours
      data1 = [];
      this.schedule.hours.forEach(h => {
        let days = h.days;
        let nameH = h.hour;
        let data: any = [];
        if (this.showEmptyHours)
        {
          data1.push(nameH);
          days.forEach(d => {
            if (d.items && d.items.length > 0)
            {
              this.formatData(d, data);
            }
            else {
              data.push('');
            }
            data1.push(data);
            data = [];
          });
          dataTable.push(data1);
          data1 = [];
        }
        else
        {
          if (!h.isEmptyHour) {
            data1.push(nameH);
            days.forEach(d => {
              if (d.items && d.items.length > 0)
              {
                this.formatData(d, data);
              }
              else {
                data.push('');
              }
              data1.push(data);
              data = [];
            });
            dataTable.push(data1);
            data1 = [];
          }
          else {
            data1 = [];
          }
        }
      });

      // Prepare to export
      let countTitles = dayTitle.length;
      rows = dataTable.map(item => {
        let row = {};
        for (let i = 0; i < countTitles; i++) {
          row[dayTitle[i]] = item[i]
        }
        return row;
      });
      // Export

      return new Promise<boolean|undefined>(resolve => setTimeout(() => {
        this.alertsService.showConfirm({
          header: 'Экспорт в Excel',
          template: { ref: this.tmpExcellPopup, context: { settings } },
          buttons: [{
            title: 'Экспортировать',
            callback: async () => {
              this.excelService.exportAsScheduleExcelFile(rows, dayTitle, {
                'mode' : this.scheduleMode,
                'categoryMode' : categoryMode,
                'categoryFilters' : categoryFilters,
                'exportFieldName' : this.exportFieldName,
                'exportFieldZal' : this.exportFieldZal,
                'exportFieldComment' : this.exportFieldComment,
                'exportFieldTrener' : this.exportFieldTrener,
                'exportFieldZanatie' : this.exportFieldZanatie,
                'exportFieldColor' : this.exportFieldColor,
                'exportFieldLevel' : this.exportFieldLevel,
                'exportFieldDuration' : this.exportFieldDuration
              }, `Расписание-${moment().format("d.MM.YYYY-HH.mm")}`);
            }
          }, {
            title: 'Отменить'
          }]
        });
      }));
    }
    if (this.scheduleMode === "today") {

      console.log('============ Schedule ============');
      console.log(this.schedule);

      // Headers
      dayTitle.push('Время');
      let firstDay = this.days[(new Date(this.datesPeriod.d1)).getDay()] + " " + (new Date(this.datesPeriod.d1)).getDate();
      dayTitle.push(firstDay);
      for (let ind = 1; ind < this.displayExtraDaysMode; ind++)
      {
        let period = new Date(this.datesPeriod.d1);
        period.setDate(period.getDate() + ind);
        let day = this.days[(period).getDay()] + " " + period.getDate();
        dayTitle.push(day);
      }
      let headers = [];
      let mainHeaders = [];
      for (let hid = 1; hid < dayTitle.length; hid++) {
        for (let cid = 0; cid < categoryFilters.length; cid++) {
          mainHeaders.push(dayTitle[hid]);
        }
      }
      for (let a = 0; a < categoryFilters.length; a++) {
        headers.push(categoryFilters[a].name);
      }
      // Data
      let _timeColumn = [];
      let _data = [];
      let _countDays = this.displayExtraDaysMode;
      let _n = this.schedule.nightTime.days;
      let _nightTime = this.schedule.nightTime.hour;
      let _shiftDays = headers.length;
      let _row = new Array(headers.length * _countDays).fill(null);
      _timeColumn.push('Time');
      _timeColumn.push('');
      _timeColumn.push(_nightTime);
      for (let t = 0; t < _countDays; t++) {
        let _items = _n[t].items;
        for (let h in _items) {
          let _item = _items[h];
          if (this.displayCategory == "room") {
            for (let s = 0; s < headers.length; s++) {
              if (_item?.room?.name == headers[s]) {
                let _a = _row[s + t * _shiftDays];
                if (_a == null) {
                  _a = [];
                  _a.push(_item);
                  if (_item.entityStatus != "draft")
                    _row[s + t * _shiftDays] = _a;
                  if (this.isDraftViewMode && _item.entityStatus == "draft")
                    _row[s + t * _shiftDays] = _a;
                } else {
                  _a.push(_item);
                  if (_item.entityStatus != "draft")
                    _row[s + t * _shiftDays] = _a;
                  if (this.isDraftViewMode && _item.entityStatus == "draft")
                    _row[s + t * _shiftDays] = _a;
                }
              }
            }
          }
          if (this.displayCategory == "couches") {
            for (let s = 0; s < headers.length; s++) {
              if (_item?.coach?.name == headers[s]) {
                let _a = _row[s + t * _shiftDays];
                if (_a == null) {
                  _a = [];
                  _a.push(_item);
                  if (_item.entityStatus != "draft")
                    _row[s + t * _shiftDays] = _a;
                  if (this.isDraftViewMode && _item.entityStatus == "draft")
                    _row[s + t * _shiftDays] = _a;
                } else {
                  _a.push(_item);
                  if (_item.entityStatus != "draft")
                    _row[s + t * _shiftDays] = _a;
                  if (this.isDraftViewMode && _item.entityStatus == "draft")
                    _row[s + t * _shiftDays] = _a;
                }
              }
            }
          }
          if (this.displayCategory == "courses") {
            for (let s = 0; s < headers.length; s++) {
              if (_item?.course?.name == headers[s]) {
                let _a = _row[s + t * _shiftDays];
                if (_a == null) {
                  _a = [];
                  _a.push(_item);
                  if (_item.entityStatus != "draft")
                    _row[s + t * _shiftDays] = _a;
                  if (this.isDraftViewMode && _item.entityStatus == "draft")
                    _row[s + t * _shiftDays] = _a;
                } else {
                  _a.push(_item);
                  if (_item.entityStatus != "draft")
                    _row[s + t * _shiftDays] = _a;
                  if (this.isDraftViewMode && _item.entityStatus == "draft")
                    _row[s + t * _shiftDays] = _a;
                }
              }
            }
          }
        }
      }
      _data.push(_row);
      _row = [];
      _row = new Array(headers.length * _countDays).fill(null);

      let _hours = this.schedule.hours;
      console.log('_hours');
      console.log(_hours);
      console.log('_countDays');
      console.log(_countDays);
      console.log('_shiftDays')
      console.log(_shiftDays);

      for (let hour = 0; hour < _hours.length; hour++) {
        let _row1 = new Array(headers.length * _countDays).fill(null);
        for (let t = 0; t < _countDays; t++) {
          let _items = _hours[hour].days;
          let _item = _items[t].items;
          for (let g = 0; g < _item.length; g++) {
            if (this.displayCategory == "room") {
              for (let s = 0; s < headers.length; s++) {
                if (_item[g]?.room?.name == headers[s]) {
                  //
                  let _s = s + t * _shiftDays;
                  let _a = _row1[_s];
                  if (_a == null) {
                    _a = [];
                    _a.push(_item[g]);
                    if (_item.entityStatus != "draft")
                      _row1[s + t * _shiftDays] = _a;
                    if (this.isDraftViewMode && _item.entityStatus == "draft")
                      _row1[s + t * _shiftDays] = _a;
                  } else {
                    _a.push(_item[g]);
                    if (_item.entityStatus != "draft")
                      _row1[s + t * _shiftDays] = _a;
                    if (this.isDraftViewMode && _item.entityStatus == "draft")
                      _row1[s + t * _shiftDays] = _a;
                  }
                }
              }
            }
            if (this.displayCategory == "couches") {
              for (let s = 0; s < headers.length; s++) {
                if (_item[g]?.coach?.name == headers[s]) {
                  //
                  let _s = s + t * _shiftDays;
                  let _a = _row1[_s];
                  if (_a == null) {
                    _a = [];
                    _a.push(_item[g]);
                    if (_item.entityStatus != "draft")
                      _row1[s + t * _shiftDays] = _a;
                    if (this.isDraftViewMode && _item.entityStatus == "draft")
                      _row1[s + t * _shiftDays] = _a;
                  } else {
                    _a.push(_item[g]);
                    if (_item.entityStatus != "draft")
                      _row1[s + t * _shiftDays] = _a;
                    if (this.isDraftViewMode && _item.entityStatus == "draft")
                      _row1[s + t * _shiftDays] = _a;
                  }
                }
              }
            }
            if (this.displayCategory == "courses") {
              for (let s = 0; s < headers.length; s++) {
                if (_item[g]?.course?.name == headers[s]) {
                  //
                  let _s = s + t * _shiftDays;
                  let _a = _row1[_s];
                  if (_a == null) {
                    _a = [];
                    _a.push(_item[g]);
                    if (_item.entityStatus != "draft")
                      _row1[s + t * _shiftDays] = _a;
                    if (this.isDraftViewMode && _item.entityStatus == "draft")
                      _row1[s + t * _shiftDays] = _a;
                  } else {
                    _a.push(_item[g]);
                    if (_item.entityStatus != "draft")
                      _row1[s + t * _shiftDays] = _a;
                    if (this.isDraftViewMode && _item.entityStatus == "draft")
                      _row1[s + t * _shiftDays] = _a;
                  }
                }
              }
            }
          }
        }
        _data.push(_row1);
      }
      for (let y = 0; y < _hours.length; y++) {
        _timeColumn.push(_hours[y].hour);
      }

      // ----- Удалять строки без занятий
      let _temp = [];
      if (!this.showEmptyHours) {
        for (let i in _data) {
          if (!this.isEmptyRow(_data[i])) {
            _temp.push(_data[i]);
          }
        }
      }
      else {
        _temp = _data;
      }

      // ----- Формируем единую таблицу с хэдерами
      let _headersFull = [];
      for (let y = 0; y < _countDays; y++) {
        for (let x = 0; x < headers.length; x++) {
          _headersFull.push(headers[x]);
        }
      }
      _temp.splice(0, 0, _headersFull);
      _temp.splice(0, 0, mainHeaders);

      // ----- Удаляем пустые столбцы
      let _emptyCol = [];
      for (let colId = 0; colId < _temp[0].length; colId++) {
        if (!this.isEmptyColumn(_temp, colId)) {
          _emptyCol.push(colId);
        }
      }

      let _resultData = [];
      for (let i = 0; i < _temp.length; i++) {
        let _row2 = [];
        for (let ind = 0; ind < _emptyCol.length; ind++) {
          _row2.push(_temp[i][_emptyCol[ind]]);
        }
        _resultData.push(_row2);
      }

      // ----- Формируем таблицу для экселя
      let _arrMax = [];
      for (let i = 2; i < _resultData.length; i++) {
        _arrMax.push(this.maxInRow(_resultData[i]));
      }

      for (let h = 0; h < _resultData.length; h++) {
        _resultData[h].splice(0, 0, _timeColumn[h]);
      }

      let _A = [];
      /*for (let i = 2; i < _resultData.length; i++) {
        let _nr = _arrMax[i - 2];
        let _row = _resultData[i];
        let _newRow = new Array(_row.length).fill(null);
        for (let j = 0; j < _nr; j++) {
          _A.push(_newRow);
        }
      }*/

      let T = 0;
      for (let i = 2; i < _resultData.length; i++) {
        let _nr = _arrMax[i - 2];
        let _row3 = _resultData[i];
        for (let j = 0; j < _nr; j++) {
          _A.push(new Array(_row3.length).fill(null));
        }
        for (let j = 0; j < _row3.length; j++) {
          let _item = _row3[j];
          if (Array.isArray(_item)) {
            for (let t = 0; t < _item?.length; t++) {
              _A[T + t][j] = _item[t];
              let _time = this.getTimeToShow(_item[t], _timeColumn);
              _A[T + t][0] = _time;
            }
          }
        }
        T += _nr;
      }
      let m1 = this.arrayPrepare(mainHeaders, _emptyCol);
      let h1 = this.arrayPrepare(_headersFull, _emptyCol);
      h1.splice(0, 0, '');
      m1.splice(0, 0, '');
      _A.splice(0, 0, m1);
      _A.splice(0, 0, h1);

      console.log('----- Prepare To Export -----');
      console.log(_A);

      // Export
      return new Promise<boolean|undefined>(resolve => setTimeout(() => {
        this.alertsService.showConfirm({
          header: 'Экспорт в Excel',
          template: { ref: this.tmpExcellPopup, context: { settings } },
          buttons: [{
            title: 'Экспортировать',
            callback: async () => {
              this.excelService.exportAsScheduleExcelFile1(_A, mainHeaders, headers, settings, `Расписание-${moment().format("d.MM.YYYY-HH.mm")}`);
            }
          }, {
            title: 'Отменить'
          }]
        });
      }));
    }
    if (this.scheduleMode === "month") {
      let data1 = [];
      this.compactSchedule.days.forEach(h => {
        h.forEach(d => {
          let day = this.days[(new Date(d.day)).getDay()] + " " + (new Date(d.day)).getDate();
          dayTitle.push(day);
          if (d.items && d.items.length > 0) {
            d.items.forEach(i => {
              let courceName = i?.course?.name || "";
              let coachName = i?.coach?.name || "";
              let zal = i?.room?.name || "";
              let comment = i?.comment || "";
              let zanatost = i?.numberOfVisits + "/" + i?.maxCapacity;
              let level = i?.level || "";
              let color = i?.course?.color || "#FFF";
              const dataItem = {
                'courceName' : courceName,
                'coachName': coachName,
                'zal' : zal,
                'comment' : comment,
                'zanatost' : zanatost,
                'level' : level,
                'color' : color,
                'isShowInSchedule': i.isShowInSchedule
              };
              data1.push(dataItem);
            });
          }
          else {
            data1.push('');
          }
          dataTable.push(data1);
          data1 = [];
        });
      });
      // Prepare to export
      let row = {};
      for (let i = 0; i < dayTitle.length; i++) {
        row[dayTitle[i]] = dataTable[i];
      }
      rows.push(row);
      return new Promise<boolean|undefined>(resolve => setTimeout(() => {
        this.alertsService.showConfirm({
          header: 'Экспорт в Excel',
          template: { ref: this.tmpExcellPopup, context: { settings } },
          buttons: [{
            title: 'Экспортировать',
            callback: async () => {
              this.excelService.exportAsScheduleExcelFile(rows, dayTitle, settings, `Расписание-${moment().format("d.MM.YYYY-HH.mm")}`);
            }
          }, {
            title: 'Отменить'
          }]
        });
      }));
    }
  }
  arrayPrepare(array: any[], columns: any[]) {
    let _res = [];
    for (let i = 0; i < columns.length; i++) {
      _res.push(array[columns[i]]);
    }
    return _res;
  }
  isEmptyRow(row: []) {
    for (let i = 0; i < row.length; i++) {
      if (row[i] != null) {
        return false;
      }
    }
    return true;
  }
  isEmptyColumn(table: any[], columnIndex: any) {
    for (let i = 2; i < table.length; i++) {
      let cell = table[i][columnIndex];
      if (cell != null) {
        return false;
      }
    }
    return true;
  }
  maxInRow(row: any[]) {
    let res = 0;
    for (let i = 0; i < row.length; i++) {
      let _c = row[i];
      if (_c?.length > res) {
        res = _c?.length;
      }
    }
    return res;
  }
  formatData(dataInput: any, data: any[]) {
    dataInput.items.forEach(i => {
      let courceName = i?.course?.name || "";
      let coachName = i?.coach?.name || "";
      let zal = i?.room?.name || "";
      let comment = i?.comment || "";
      let zanatost = i?.numberOfVisits + "/" + i?.maxCapacity;
      let level = i?.level || "";
      let color = i?.course?.color || "#FFF";
      let duration = i?.duration || 0;
      let startTime = i?.startTime;
      let _ = moment('01.01.1997 ' + i?.startTime).add(duration, 'm').toDate();
      let period = _.getHours() + ":" + String(_.getMinutes()).padStart(2, '0');
      const dataItem = {
        'courceName' : courceName,
        'startTime' : startTime,
        'duration' : duration,
        'period' : period,
        'coachName': coachName,
        'zal' : zal,
        'comment' : comment,
        'zanatost' : zanatost,
        'level' : level,
        'color' : color,
        'isShowInSchedule': i.isShowInSchedule
      };
      if (i?.entityStatus == "draft" && this.isDraftViewMode)
      {
        data.push(dataItem);
      }
      if (i?.entityStatus == "published")
      {
        data.push(dataItem);
      }
    });
  }
  setDataToExport(value) {
    let _ = window.localStorage.getItem(value);
    let b = (_ == "false" || _ == null) ? false : true;
    let s = String(!b);
    window.localStorage.setItem(value, s);
  }
  getTimeToShow(item: any, times: any[]) {
    let _a = item.startTime;
    let _d = moment(_a, 'hh:mm');
    for (let i = 3; i < times.length; i++) {
      let _t = moment(times[i], 'hh:mm');
      if (_d < _t) {
        return times[i - 1];
      }
    }
  }

  protected readonly window = window;
  protected readonly window = window;
  protected readonly window = window;
  protected readonly window = window;
  protected readonly window = window;
  protected readonly window = window;
  protected readonly window = window;
  protected readonly document = document;
  protected readonly String = String;
  protected readonly String = String;
  protected readonly document = document;
}
