import { OnInit, Injector, OnDestroy, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { IUserInfo } from 'app/common/models/user-info.model';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { ICityModel } from 'app/common/models/city-model';

import { Subject } from 'rxjs';
import { takeUntil, debounceTime, filter } from 'rxjs/operators';
import { IRoutingParams } from 'app/common/models/context.model';
import { IPagedList } from 'app/common/models/common.models';
import { ISchedule } from 'app/common/models/schedule.model';
import { AuthService } from 'app/common/services/auth.service';
import { CityService } from 'app/common/services/city.service';
import { ContextService } from 'app/common/services/context.service';
import { PersistenceService } from 'app/common/services/persistence.service';
import { ITableListService } from './table-list.service';
import { PermissionService } from '../services/permission.service';

@Directive()
export abstract class TableListComponent<T extends { id: string } | ISchedule> implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject();
  public userInfo: IUserInfo = null;
  protected permissionService: PermissionService;
  public authService: AuthService;
  public contextService: ContextService;
  protected hotkeysService: HotkeysService;
  public persistenceService: PersistenceService;
  public cityService: CityService;
  public router: Router;
  public route: ActivatedRoute;
  protected hkAdd: Hotkey | Hotkey[];
  protected hkClose: Hotkey | Hotkey[];
  // TODO: грязный хак чтобы можно было типизовать расписание как tablelist
  public Model: T[] | T = null;
  public currentCity: ICityModel = null;
  public routingParams: IRoutingParams;
  public parentId: string;
  public scrollPosition: number = null;
  public pageIndex: number = 0;

  itemListCount: number = 0;

  protected getTotalItemCount(): number {
    return this['totalItemCount'] || this.itemListCount;
  }
  protected getPageSize(): number {
    return this['pageSize'] || 50;
  }
  protected getModel(): any[] | any {
    return this.Model;
  }

  constructor(
    injector: Injector,
    protected service: ITableListService
  ) {
    this.permissionService = injector.get(PermissionService);
    this.authService = injector.get(AuthService);
    this.contextService = injector.get(ContextService);
    this.persistenceService = injector.get(PersistenceService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.hotkeysService = injector.get(HotkeysService);
    this.cityService = injector.get(CityService);
    this.contextService.routingParams.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.routingParams = params;
      })
    this.hkAdd = this.hotkeysService.add(new Hotkey(['=', 'ins', 'plus'], this.hkAddPress(this), []));
    this.hkClose = this.hotkeysService.add(new Hotkey('esc', this.hkClosePress(this), ['INPUT', 'TEXTAREA', 'SELECT']));
  };

  public onModelLoadCallback() {
    if (localStorage.getItem('scrollPosition')) {
      const scroll = JSON.parse(localStorage.getItem('scrollPosition')).scroll;
      if (scroll) { this.scrollPosition = scroll; localStorage.removeItem('scrollPosition') }
    }
    setTimeout(() => {
      if (this.scrollPosition) {
        window.innerWidth >= 992 ?
          document.querySelector('.app_container').scrollTop = this.scrollPosition :
          document.querySelector('.app_container').scrollTop = document.documentElement.scrollTop = this.scrollPosition;
        this.scrollPosition = null;
      }
    }, 1);
  }


  private setItemListCount(totalItemCount: number) {

    this.itemListCount = totalItemCount;

  }

  public showNextPage() {
    this.pageIndex += 1;
    this.service.getItemList(this.currentCity, this.pageIndex, this.getPageSize())
      .then((res: IPagedList<any>) => {
        if (Array.isArray(this.Model)) {
          this.Model = [...this.Model, ...res.items];
        } else if (this.Model) {
          this.Model = [this.Model, ...res.items];
        } else {
          this.Model = res.items;
        }
        this.setItemListCount(res.pagingInfo.totalItemCount);
      });
  }

  public errorHandler(e) {
    console.log(e);
  }

  protected hkAddPress(that: this) {
    return () => {
      that.onEditStart(null);
      return true;
    };
  }

  protected hkClosePress(that: any) {
    return (event: KeyboardEvent) => {
      event.stopPropagation();
      that.close();
      return true;
    };
  }

  public close(): void {
    if (this.parentId) {
      this.router.navigate([`${this.parentId}`], { relativeTo: this.route });
    }
  }

  protected get isEditAvailable() {
    return this.permissionService.isAvailable(this, 'isEditAvailable', this.userInfo?.role);
  }

  setStatus(event: string, item: T) {
    this.service.setStatus(event, item);
  }

  private isPagedList(x): x is IPagedList<any> {
    return (x as IPagedList<T>).items !== undefined;
  }

  updateModel() {
    const pageIndex = Number(this.route.snapshot.queryParamMap.get('pageIndex')  || 0);
    const pageSize = Number(this.route.snapshot.queryParamMap.get('pageSize') || this.getPageSize()  || 10);
    return this.service.getItemList(this.currentCity, pageIndex, pageSize, this.parentId)
      .then(x => {
        if (this.isPagedList(x)) {
          this.Model = x.items;
          this.setItemListCount(x.pagingInfo.totalItemCount);
        } else {
          this.Model = x
        }
        this.onModelLoadCallback();
      });
  }

  onEditStart(item: { id?: string }, event?: MouseEvent) {
    if ((event.target as Element).className === 'onRemoveEvent ng-star-inserted') return;

    if (event && (event.button === 2 || event.ctrlKey)) {
      if (item != null) {
        const url = this.router.createUrlTree([`${item.id}`], { relativeTo: this.route, queryParams: { parentId: this.parentId } });
        window.open(url.toString());
      } else {
        const url = this.router.createUrlTree([`create`], { relativeTo: this.route, queryParams: { parentId: this.parentId } });
        window.open(url.toString());
      }

      return false;
    }

    if (window.innerWidth <= 992) {
      this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    } else {
      this.scrollPosition = document.querySelector('.app_container').scrollTop;
    }

    localStorage.setItem('scrollPosition', JSON.stringify({ scroll: this.scrollPosition }));

    localStorage.setItem('tapClientTableId', JSON.stringify({ id: item?.id }));

    if (item != null) {
      this.router.navigate([`${item.id}`], { relativeTo: this.route, queryParams: { parentId: this.parentId } });
    } else {
      this.router.navigate([`create`], { relativeTo: this.route, queryParams: { parentId: this.parentId } });
    }
  }

  ngOnInit() {
    if (!this.persistenceService.checkSheduledRedirect()) {
      if (this.persistenceService.checkCreationStatus()) {
        // Указывает, что при загрузке справочника сущностей
        // должна быть немедленно создана новая сущность такого типа
        this.onEditStart(null);
      }

      this.authService.getUserInfo()
        .then(res => this.userInfo = res);

      this.cityService.currentCity.pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(100),
        filter(Boolean))
        .subscribe(city => {
          this.currentCity = city as ICityModel;
          this.parentId = this.route.snapshot.queryParamMap.get('parentId') || null;
          this.updateModel().catch(e => this.errorHandler(e));
        })
    }
  }

  ngOnDestroy() {
    this.hotkeysService.remove(this.hkAdd);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
