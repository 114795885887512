import { ClubSetting, ClubSettingVariant } from './../../common/models/club-setting.model';
import { ContextService } from './../../common/services/context.service';
import { ApiService } from './../../common/services/api.service';
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ClubSettingService {
  constructor(
    private api: ApiService,
    private contextService: ContextService
  ) { }

  public getClubSettingVariants() {
    return this.api.clubSettingVariants.getAll();
  }

  public getClubSettingsByClub(clubId?: string, incrementRequestCount: boolean = true) {
    if (!clubId)
      clubId = this.contextService.getRoutingParams().clubId;

    if (!clubId) {
      console.error("Не удалось получить идентификатор клуба.");
      return;
    }

    return this.api.clubSettings.getByClub(clubId, incrementRequestCount)
  }

  public async saveClubSetting(item: ClubSetting) {
    if (!item.clubId)
      item.clubId = this.contextService.getRoutingParams().clubId;

    if (!item.clubId) {
      console.error("Не удалось получить идентификатор клуба.");
      return;
    }

    await this.api.clubSettings.save(item);
    this.contextService._updateContext.next(true);
  }

  public variantToClubSetting(v: ClubSettingVariant) {
    const item: ClubSetting = {
      alias: v.alias,
      value: v.value.toString()
    };

    return item;
  }
}
