<header @animation class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="_close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание занятия</span>
      <span class="content__header-title-value" *ngSwitchDefault>Карточка занятия</span>
    </div>
      <a class="content__header-link" (click)="_close()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
  </div>
</header>
<hr>

<div class="content__body" *ngIf="Model">
  <div class="row" *ngIf="Model.id" @animation>
    <div class="col-xs-12">
      <div class="short-info">
        <ul>
          <li *ngIf="Model.isDenyRecording"><b style="color: red;">Бронирование выключено</b></li>
          <li *ngIf="Model.isHideClassForClients"><b style="color: red;">Скрыто от клиентов</b></li>
          <li><b>{{Model.isNotRecurrent ? 'Разовое занятие' : 'Повторяющееся занятие'}}</b></li>
          <li>Начинается: <b>{{Model.startDate}} в {{Model.startTime}}</b></li>
          <li>Продолжительность: <b>{{Model.duration}}</b></li>
          <li *ngIf="Model.course">Вид занятий: <b>{{Model.course.name}}</b></li>
          <li *ngIf="Model.room">Зал: <b>{{Model.room.name}}</b></li>
          <li *ngIf="Model.coach">Тренер: <b>{{Model.coach.name}}</b></li>
          <li>Кол-во мест: <b>{{Model.maxCapacity}}</b></li>
        </ul>
        <button type="button" (click)="setShowDetails()">{{!isShowDetails ? '[Редактировать]' : '[Скрыть]'}}</button>
      </div>
    </div>
  </div>

  <form [formGroup]="form" novalidate>
    <ng-container *ngIf="isShowDetails">
      <div @animation>
        <div class="row">
          <div class="col-xs-5 col-sm-6 col-md-4 radio-button__wrapper">
            <input type="radio" [(ngModel)]="Model.isNotRecurrent" formControlName="isNotRecurrent" class="radio-button"
                   name="recurrent" id="isNotRecurrent-{{Model.id}}" [value]=true (change)="isRecurent($event.target.value)">
            <label class="box-title" for="isNotRecurrent-{{Model.id}}">Разовое занятие</label>
          </div>
          <div class="col-xs-7 col-sm-6 col-md-4 radio-button__wrapper">
            <input type="radio" [(ngModel)]="Model.isNotRecurrent" formControlName="isNotRecurrent" class="radio-button"
                   name="recurrent" id="recurrent-{{Model.id}}" [value]=false (change)="isRecurent($event.target.value)">
            <label class="box-title" for="recurrent-{{Model.id}}">Повторяющееся занятие</label>
          </div>
        </div>
        <ng-container *ngIf="!form.controls['isNotRecurrent'].invalid && Model.isNotRecurrent !== null">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Дата начала</div>
                <div class="row__field">
                  <app-date-picker formControlName="startDate" [(ngModel)]="Model.startDate" id="startDate">
                  </app-date-picker>
                  <label for="startDate" class="field-validation-error"
                         *ngIf="fieldErrors.startDate && isSubmited">{{fieldErrors.startDate}}</label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Время начала</div>
                <div class="row__field">
                  <input type="time" class="input__text input__text--long" formControlName="startTime" id="startTime"
                         [ngModel]="Model.startTime">
                  <label for="startTime" class="field-validation-error"
                         *ngIf="fieldErrors.startTime && isSubmited">{{fieldErrors.startTime}}</label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Продолжительность</div>
                <div class="row__field">
                  <input type="number" class="input__text input__text--long" formControlName="duration" id="duration"
                         [ngModel]="Model.duration">
                  <label for="duration" class="field-validation-error"
                         *ngIf="fieldErrors.duration && isSubmited">{{fieldErrors.duration}}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <div class="box-title">Новый набор</div>
                <div class="row__field">
                  <input type="checkbox" [ngModel]="Model.isNewGroup" formControlName="isNewGroup"
                    class="checkbox__slide" name="isNewGroup" id="isNewGroup-{{Model.id}}">
                  <label for="isNewGroup-{{Model.id}}" class="checkbox__slide_label"></label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <div class="box-title">Скрыть занятие у клиентов</div>
                <div class="row__field">
                  <input type="checkbox" [(ngModel)]="Model.isHideClassForClients" formControlName="isHideClassForClients"
                         class="checkbox__slide" name="isHideClassForClients" id="isHideClassForClients-{{Model.id}}">
                  <label for="isHideClassForClients-{{Model.id}}" class="checkbox__slide_label"></label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <div class="box-title">Запретить запись</div>
                <div class="row__field">
                  <div class="block-isDenyRecording">
                    <input type="checkbox" [(ngModel)]="Model.isDenyRecording" formControlName="isDenyRecording"
                      class="checkbox__slide" name="isDenyRecording" id="isDenyRecording-{{Model.id}}"
                      (ngModelChange)="changeDenyRecording()">
                    <label for="isDenyRecording-{{Model.id}}" class="checkbox__slide_label"></label>
                  </div>
                  <div class="block-messageDenyRecording">
                    <input class="input__text input__text--long" placeholder="Сообщение для клиентов" type="text"
                      formControlName="messageDenyRecording" [(ngModel)]="Model.messageDenyRecording">
                  </div>
                </div>
                <div class="row__field">
                </div>
              </div>
            </div>

            <div class="col-xs-12" *ngIf="Model.onlineType == 'online'">
              <div class="form-group" myScrollTo>
                <div class="box-title">Ссылка на трансляцию</div>
                <div class="row__field">
              <textarea style="resize: none" type="text" placeholder="" formControlName="streamUrl" id="streamUrl"
                        [ngModel]="Model.streamUrl">
              </textarea>
                  <label for="streamUrl" class="field-validation-error"
                         *ngIf="fieldErrors.streamUrl && isSubmited">{{fieldErrors.streamUrl}}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Вид занятия</div>
                <div class="row__field">
                  <div class="selectWithButton">
                    <div>
                      <app-object-picker [objects]="courses" [placeholder]="'Не выбрано'" [groupBy]="'group'"
                                         [ngModel]="Model.course" id="course" formControlName="course"></app-object-picker>
                      <label for="course" class="field-validation-error"
                             *ngIf="fieldErrors.course && isSubmited">{{fieldErrors.course}}</label>
                    </div>
                    <div>
                      <button type="button" class="btn btn-primary btn-small" (click)="addCourse()">
                        <span class="btn-primary__desc">+</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Тренер</div>
                <div class="row__field">
                  <div class="selectWithButton">
                    <div>
                      <app-object-picker [objects]="coaches" [placeholder]="'Не выбрано'" [(ngModel)]="Model.coach" id="coach"
                                         formControlName="coach"></app-object-picker>
                      <label for="coach" class="field-validation-error"
                             *ngIf="fieldErrors.coach && isSubmited">{{fieldErrors.coach}}</label>
                    </div>
                    <div>
                      <button type="button" class="btn btn-primary btn-small" (click)="addCoach()">
                        <span class="btn-primary__desc">+</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Зал</div>
                <div class="row__field">
                  <div class="selectWithButton">
                    <div>
                      <app-object-picker [objects]="rooms" [placeholder]="'Не выбрано'" [ngModel]="Model.room" id="room"
                                         formControlName="room"></app-object-picker>
                      <label for="room" class="field-validation-error"
                             *ngIf="fieldErrors.room && isSubmited">{{fieldErrors.room}}</label>
                    </div>
                    <div>
                      <button type="button" class="btn btn-primary btn-small" (click)="addClubroom()">
                        <span class="btn-primary__desc">+</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4" [hidden]="true">
              <div class="form-group" myScrollTo>
                <div class="box-title">Комфортная вместимость</div>
                <div class="row__field">
                  <input type="number" class="input__text input__text--long" id="normCapacity"
                         formControlName="normCapacity" [ngModel]="Model.normCapacity">
                  <label for="normCapacity" class="field-validation-error"
                         *ngIf="fieldErrors.normCapacity && isSubmited">{{fieldErrors.normCapacity}}</label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Онлайн / Open air</div>
                <div class="row__field">
                  <mat-select class="select select__long" formControlName="onlineType" id="onlineType" [ngModel]="Model.onlineType" (ngModelChange)="onOnlineTypeChange($event)">
                    <mat-option value="none">нет</mat-option>
                    <mat-option value="online">онлайн</mat-option>
                    <mat-option value="openair">open air</mat-option>
                  </mat-select>
                  <label for="onlineType" class="field-validation-error"
                         *ngIf="fieldErrors.onlineType && isSubmited">{{fieldErrors.onlineType}}</label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Кол-во мест</div>
                <div class="row__field">
                  <input type="number" class="input__text input__text--long" id="maxCapacity"
                    formControlName="maxCapacity" [ngModel]="Model.maxCapacity">
                  <label for="maxCapacity" class="field-validation-error"
                         *ngIf="fieldErrors.maxCapacity && isSubmited">{{fieldErrors.maxCapacity}}</label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Уровень</div>
                <div class="row__field">
                  <input class="input__text input__text--long" formControlName="level" id="level"
                    [ngModel]="Model.level" placeholder="Уровень" />
                  <label for="level" class="field-validation-error"
                         *ngIf="fieldErrors.level && isSubmited">{{fieldErrors.level}}</label>
                  <!-- <select class="select select__long" formControlName="level" id="level" [ngModel]="Model.level">
                    <option disabled selected>Уровень</option>
                    <option *ngFor="let level of levels" [value]="level">{{level|level2string}}</option>
                  </select>
                  <label for="level" class="field-validation-error" *ngIf="fieldErrors.level && isSubmited">{{fieldErrors.level}}</label> -->
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group" myScrollTo>
                <div class="box-title">Комментарий</div>
                <div class="row__field">
              <textarea style="resize: none" type="text"
                        placeholder="Коротко укажите особенность занятия, на что обратить внимание клиентам"
                        formControlName="comment" id="comment" [ngModel]="Model.comment" maxlength="70">
                  </textarea>
                  <label for="comment" class="field-validation-error"
                         *ngIf="fieldErrors.comment && isSubmited">{{fieldErrors.comment}}</label>
                </div>
              </div>
            </div>

            <div class="col-xs-12" *ngIf="subscriptionPlans && subscriptionPlans.length">
              <div class="form-group" myScrollTo>
                <div class="box-title">Подходящие aбонементы</div>
                <div class="row__field">
                  <ul class="category-list">
                    <li *ngFor="let plan of subscriptionPlans">
                      <a [routerLink]="['../../../clubplans', plan.id]">{{plan.name}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!form.controls['isNotRecurrent'].invalid && Model.isNotRecurrent !== null">
      <div  @animation class="row">
        <div class="search-box col-12 col-xs-12 col-md-6 col-lg-4" id="search-block">
          <div class="box-title">Регистрация на занятие</div>
              <div class="form-group">
                <input class="input__text input__text--long" autocomplete="off" #searchBox id="search-box"
                  (keyup)="searchClients(searchBox.value)" placeholder="Имя, телефон, email клиента" />
                <ul class="search-box__list" *ngIf="clientSearchList && clientSearchList.length" appClickOutside
                  (clickOutside)="clientSearchList = []">
                  <ng-container *ngFor="let client of clientSearchList">
                    <li class="search-box__list-item" *ngIf="client" (click)="addBooking($event, client)">
                      {{client.firstName}} {{client.lastName}}
                      <span *ngIf="client.phone">({{client.phone}})</span>
                      <button type="button" class="btn btn-special" (click)="addBooking($event, client)">Записать</button>
                    </li>
                  </ng-container>
                </ul>
              </div>
            <div class="form-group">
              <button type="button"*ngIf="searchBox.value.length > 2" class="btn btn-primary btn-small"
                (click)="createAndAddBooking()">
                <span class="btn-primary__desc">
                  Создать нового клиента и записать
                </span>
              </button>
            </div>
          </div>
        <div class="col-12 col-xs-12 col-md-6 col-lg-4">
          <div class="form-group">
            <div class="box-title">Группы</div>
            <mat-select class="input__text input__text--long select-group"
              (selectionChange)="addGroup($event.value)" [formControl]="groupControl">
              <mat-option [value]="''" style="display: none;">Не выбрано</mat-option>
              <mat-option [value]="group.id" *ngFor="let group of groups">{{group.name}}</mat-option>
            </mat-select>
        </div>
        </div>
      </div>
      <div  @animation class="form-group" *ngIf="classGroups && classGroups.length">
        <div class="box-title">Группы участников</div>
          <ul class="category-list">
            <li *ngFor="let classGroup of classGroups;">
                <span class="btn btn-link" (click)="showGroup(classGroup, $event)" (contextmenu)="showGroup(classGroup, $event)">{{classGroup.name}}</span>
                <button type="button" class="btn-clear" (click)="confirmRemoveGroup(classGroup)">
                    <span class="icon-cross__left"></span>
                    <span class="icon-cross__right"></span>
                </button>
            </li>
          </ul>
      </div>
      <div  @animation *ngIf="groupVisits && groupVisits.length" class="form-group">
        <div class="box-title">Участники</div>
        <div class="row">
          <div class="col-xs-12">
            <div class="info-text icons-prompt">
              <p>
                <span class="icon-check icons-prompt">
                        <span class="icon-check__left"></span>
                        <span class="icon-check__right"></span>
                      </span>
                <span class="icons-description">&mdash; отмечает посещение занятия и списывает одно посещение с
                  абонемента</span>
              </p>
              <p>
                <span class="icon-cross icons-prompt">
                        <span class="icon-cross__minus"></span>
                      </span>
                <span class="icons-description">&mdash; отмечает прогул занятия клиентом и списывает одно посещение с
                  абонемента</span>
              </p>
              <p>
                      <span class="icon-cross icon-delete">
                        <span class="icon-cross__left"></span>
                        <span class="icon-cross__right"></span>
                      </span>
                <span class="icons-description"> ("Отмена посещения") &mdash; отменяет запись клиента на занятие,
                  посещение с абонемента не списывается</span>
              </p>
                  </div>
                        </div>
                        </div>
        <div class="sortTable" [formGroup]="formForSort">
          <select class="box-link_wrap select sortSelect" formControlName="sortOption" (change)="changeSortOptionTable($event)">
            <option [value]="''" disabled>--Выберите фильтр--</option>
            <option [value]="'sortClient-asc'">По алфавиту А ➡ Я</option>
            <option [value]="'sortClient-desc'">По алфавиту Я ➡ А</option>
            <option [value]="'sortStatus-asc'">По статусу ⬇</option>
            <option [value]="'sortStatus-desc'">По статусу ⬆</option>
            <option [value]="'sortSubscription-asc'">Сначала с абонементами</option>
            <option [value]="'sortSubscription-desc'">Сначала без абонементов</option>
            <option [value]="'sortFrom-asc'">По группе ⬇</option>
            <option [value]="'sortFrom-desc'">По группе ⬆</option>
          </select>
        </div>
            <div class="table rowHover check-visit">
          <div class="table-row_head">
                  <div class="table-cell table-title-cell">Пришел/Пропустил</div>
            <div class="table-cell table-title-cell" (click)="sortOptionChange('sortClient')" [ngClass]="sortArrow('sortClient')">Клиент</div>
            <div class="table-cell table-title-cell column-status" (click)="sortOptionChange('sortStatus')" [ngClass]="sortArrow('sortStatus')">Статус</div>
            <div class="table-cell table-title-cell" (click)="sortOptionChange('sortSubscription')" [ngClass]="sortArrow('sortSubscription')">Абонемент</div>
            <div class="table-cell table-title-cell" (click)="sortOptionChange('sortFrom')" [ngClass]="sortArrow('sortFrom')">Откуда?</div>
            <div class="table-cell table-title-cell"></div>
                </div>
          <div *ngFor="let classVisit of groupVisits; index as i" [class.canceled-visit]="classVisit.isCanceled" [class.missed-visit]="classVisit.isMissed" class="table-row"
            [ngStyle]="styleBorderLeftRow(classVisit)"
          >
                  <div class="table-cell table-cell_action">
                    <ng-container *ngIf="classVisit.currentVisitStatus != 'Queued'">
                      <button type="button" class="btn btn-clear active check-visit_btn" [disabled]="classVisit.disabled" (click)="isVirtualId(classVisit.id) ? confirmForGroupUser(classVisit) : confirmVisited(classVisit)">
                        <span class="icon-check">
                          <span class="icon-check__left"></span>
                          <span class="icon-check__right"></span>
                        </span>
                      </button>
                      <span class="mobile__label">Пришел</span>
                      <button type="button" class="btn btn-clear active check-visit_btn" [disabled]="classVisit.disabled" (click)="isVirtualId(classVisit.id) ? missForGroupUser(classVisit) : confirmMissed(classVisit)">
                        <span class="icon-cross">
                          <span class="icon-cross__minus"></span>
                        </span>
                      </button>
                      <span class="mobile__label">Пропустил</span>
                    </ng-container>
                    <ng-container *ngIf="classVisit.currentVisitStatus == 'Queued'">
                      <button type="button" class="btn btn-clear btn__delete active check-visit_btn" (click)="confirmQueued(classVisit)">
                        <span class="icon-check" style="background-color: #ffd4bc;">
                          <span class="icon-check__left"></span>
                          <span class="icon-check__right"></span>
                        </span>
                      </button>
                      <span class="mobile__label">Записать</span>
                      <div class="row-not-mobile" style="width: 100%;">
                        <button type="button" style="width: 100%;" class="btn-special" (click)="confirmQueued(classVisit)">
                          Записать
                        </button>
                      </div>
                    </ng-container>
                    <button type="button" class="btn btn-clear active btn__delete check-visit_btn" [disabled]="classVisit.disabled" (click)="isVirtualId(classVisit.id) ? cancelForGroupUser(classVisit) : cancelBooking(classVisit)">
                        <span class="icon-cross icon-delete">
                          <span class="icon-cross__left"></span>
                          <span class="icon-cross__right"></span>
                        </span>
                    </button>
                    <span class="mobile__label">Отмена посещения</span>
                  </div>
            <!-- Client -->
                  <div class="table-cell">
                    <div class="table-cell__client_wrap">
                      <div class="table-cell__avatar">
                  <div class="table-cell__avatar-default" *ngIf="!classVisit.primaryImageUrl">
                          <span class="sp sp-user"></span>
                        </div>
                  <div class="table-cell__avatar-default" *ngIf="classVisit.primaryImageUrl"
                    [style.background]="'url('+classVisit.primaryImageUrl+')'"></div>
                      </div>
                      <div class="table-cell__client__desc">
                        <div (click)="goToClient(classVisit, $event)" (contextmenu)="goToClient(classVisit, $event)" class="client__desc">
                          <span class="table-cell__client__name">{{getFullName(classVisit)}}</span>
                    <span class="table-cell__client__phone" *ngIf="classVisit.phone; else noPhone">{{
                      classVisit.phone}}</span>
                          <ng-template #noPhone>
                            <span class="table-cell__client__phone gray">[не&nbsp;указано]</span>
                          </ng-template>
                    <div class="icon-from-type"
                      *ngIf="classVisit.accountStatus !== 'clubClient' && classVisit.accountStatus === 'registered'">
                          <span class="sp sp-sp" title="Польз-ль приложения SP">
                            <span class="icon__label">Польз-ль приложения</span>
                          </span>
                          </div>
                  <!--
                    <span class="table-cell__client__visit-status"
                      [ngClass]="{canceled: !classVisit.currentVisitStatus === 'Booked'}">{{
                      classVisit.currentVisitStatusText }}</span>
                          <ng-container *ngIf="classVisit.currentVisitStatus === 'Booked'">
                      <span class="table-cell__client__booking-time">Дата бронирования: {{ classVisit.bookingTime }}</span>
                          </ng-container>
                  -->
                    <ng-container
                      *ngIf="classVisit.canceledAt && (classVisit.currentVisitStatus === 'CancelledByClub' || classVisit.currentVisitStatus === 'CancelledByUser')">
                      <span class="table-cell__client__booking-time">Дата отмены: {{ classVisit.canceledAt |
                        date:'dd.MM.yyyy HH:mm' }}</span>
                          </ng-container>
                        </div>
                        <ng-container *ngIf="classVisit.currentVisitStatus === 'WaitingBookingConfirmation'">
                          <a (click)="confirmEvent(classVisit)" class="btn-WaitingBookingConfirmation">Подтвердить</a>
                        </ng-container>
                      </div>
                    </div>
                  </div>
            <!-- Status -->
            <div class="table-cell column-status">
              <span>{{ classVisit.currentVisitStatusText }}</span>
              <br>
              <span class="table-cell__client__booking-time">Дата бронирования: {{ classVisit.bookingTime }}</span>
            </div>
            <!-- Aboniments (Subscriptions) -->
                  <div class="table-cell">
              <div class="row__field"
                *ngIf="classVisit.subscriptions.length; else noSubscription">
                      <select setHeight class="select select__long" [disabled]="classVisit.disabled"
                        (change)="isVirtualId(classVisit.id) ? createVisitForGroupUser(classVisit, $event.target.value) :  selectSubscriptionForClient(classVisit, $event.target.value)">
                        <option *ngFor="let subscription of classVisit.subscriptions" [selected]="subscription['isSelected']"
                                [value]="subscription.id" [disabled]="subscription.disabled" [class.warn]="subscription.isWarn">
                                {{subscription.name}} {{subscription.extraInfo}}
                        </option>
                        <option [value]="null">Купить другой абонемент</option>
                      </select>
<!--                      <mat-select class="select select__long">-->
<!--                        <mat-option [value]="null">Купить другой абонемент</mat-option>-->
<!--                        <mat-option value="option1">Option 1</mat-option>-->
<!--                      </mat-select>-->
                    </div>
                    <ng-template #noSubscription>
                      <div>
                        <button type="button" class="btn btn-alert" (click)="showSubscriptions(classVisit.clientId)">Купить
                          абонемент</button>
                      </div>
                    </ng-template>
                  </div>
            <!-- groups -->
                  <div class="table-cell">
              <ul class="booking-source">
                <li *ngFor="let source of classVisit.groups; index as i;" [class.btn]="source.id" [class.btn-link]="source.id"
                    [style]="'padding: 0.7rem 0;'" (click)="source.id ? showGroup(source, $event) : null" (contextmenu)="source.id ? showGroup(source, $event) : null">
                  {{source.name}}<span *ngIf="classVisit.groups.length > 1 && i+1 !== classVisit.groups.length">,</span>
                </li>
              </ul>
                  </div>
            <!-- cancel -->
            <div class="table-cell row-not-mobile">
              <button type="button" class="btn-special" style="width: 100%" [disabled]="classVisit.disabled"
                (click)="isVirtualId(classVisit.id) ? cancelForGroupUser(classVisit) : cancelBooking(classVisit)">
                Отменить
              </button>
                </div>
              </div>
            </div>
        </div>

      <div  @animation class="row">
        <div class="col-12 col-xs-12">
          <div class="info-text">
            <div class="info-text__box" *ngIf="isVirtual()">
              <span class="icon icon-about"></span>
              Это занятие автоматически повторяется каждую неделю.
            </div>

            <div [ngClass]="{'info-text__box' : !isNotSaved()}">
              <span class="icon icon-about"></span>
              <span app-key-help [key]="'draftText'"></span>
            </div>

            <ng-container *ngIf="!isNotSaved()">
              <div>
                <span class="icon icon-about"></span>
                Статус:
                <ng-container *ngIf="Model.classStatus === 'available'">Доступно</ng-container>
                <ng-container *ngIf="Model.classStatus === 'cancelled'">Отменено</ng-container>
                <ng-container *ngIf="Model.classStatus === 'moved'">Перемещено</ng-container>

                <sup *ngIf="Model.classStatus === 'cancelled' && Model.isShowInSchedule">
                  *видно в расписании
                </sup>
              </div>
              <div *ngIf="Model.cancellationReason && Model.classStatus === 'cancelled'" class="sp-information" style="font-size: 12px;"> Причина отмены: {{Model.cancellationReason}}</div>
            </ng-container>
          </div>

        </div>

        <div class="col-12 col-xs-12 col-sm-6 col-md-3" *ngIf="isEditAvailable">
          <div class="form-group">
            <div class="row__field">
              <button type="button" class="btn btn-primary" (click)="confirmDate(true)"
                [disabled]="isSubmited && form.invalid || (isPublished() && !modelChanged)">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-xs-12 col-sm-6 col-md-3" *ngIf="isEditAvailable">
          <div class="form-group">
            <div class="row__field">
              <button type="button" class="btn btn-primary" (click)="confirmDate(false)"
                [disabled]="isSubmited && form.invalid || isClientsScheduled">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveDraft'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-xs-12 col-sm-6 col-md-3" *ngIf="isEditAvailable">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button type="button" class="btn btn-link" (click)="confirmCancel(false)">
                  <span>Отменить и удалить из расписания</span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-3" *ngIf="isEditAvailable">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button type="button" class="btn btn-link" (click)="confirmCancel(true)">
                  <span>Отменить и показывать в расписании</span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
