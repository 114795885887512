import { Injectable, Injector } from '@angular/core';
import { IClubModel, IExtendedClubModel } from 'app/common/models/club.model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { IRoutingParams } from 'app/common/models/context.model';
import { Router } from '@angular/router';
import { ICityModel } from 'app/common/models/city-model';

@Injectable()
export class ClubsService extends TableListService<IClubModel | IExtendedClubModel> {
  setStatus(status: string, item: IExtendedClubModel) {
    return this.apiService.clubs.setStatus(this.contextService.getRoutingParams(), status, item);
  }

  changeStatus(status: string) {
    return this.apiService.clubs.changeStatus(this.contextService.getRoutingParams(), status);
  }

  getItemList(city?: ICityModel, pageIndex?: number, pageSize?: number) {
    return this.apiV2Service.clubs.getAll(city, pageIndex, pageSize);
  }

  getSingleItem(req: IRoutingParams) {
    return this.apiService.clubs.getItem(req);
  }

  search(text: string, city?: ICityModel) {
    return this.apiV2Service.search.clubs(text, city);
  }

  save(model: IExtendedClubModel) {
    const ctx = this.contextService.getRoutingParams();
    ctx.clubId = null;
    return this.apiService.clubs.saveItem(ctx, model);
  }

  create(model: IExtendedClubModel) {
    const ctx = this.contextService.getRoutingParams();
    Object.assign(ctx, { id: null });
    return this.apiService.clubs
      .saveItem(ctx, model)
      .then(res =>
        this.router.navigate([`${ctx.cityId}/${res.clubNetId}/${res.clubId}/club`])
      );
  }

  public getClubsByNet(context: IRoutingParams) {
    return this.apiService.clubs.getClubsByNet(context);
  }

  public importClients(context: IRoutingParams, file: File) {
    return this.apiService.clubs.importClients(context, file);
  }
  public testImportClients(context: IRoutingParams, file: File) {
    return this.apiService.clubs.testImportClients(context, file);
  }
  constructor(
    injector: Injector,
    private router: Router
  ) {
    super(injector);
  }
}
