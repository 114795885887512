import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
  selector: '[appMessageType]'
})
export class MessageTypeDirective implements AfterViewInit {

  constructor(private readonly renderer: Renderer2,
              private router: Router,
              private el: ElementRef) { }

  @Input('messageText') messageText: any;

  ngAfterViewInit() {
    this.linkify();
  }

  private linkify() {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    if (this.messageText.includes(location.origin)) {
      this.el.nativeElement.innerHTML = this.messageText.replace(urlPattern, (url) => `<a>${url}</a>`);

      const links = this.el.nativeElement.querySelectorAll('a');
      links.forEach(link => {
        this.renderer.listen(link, 'click', (event) => {
          const url = link.innerText.split(location.origin);
          this.router.navigateByUrl(url[url.length - 1]).then()
        });
      });
    } else {
      this.el.nativeElement.innerHTML = this.messageText.replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`);
    }
  }
}
