import { Component, Injector, HostListener } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { EditItemComponent } from 'app/common/edit-item.component';
import { dateValidator } from 'app/common/validators/date.validator';
import { IClientModel } from 'app/common/models/client.model';

import { SubscriptionService } from 'app/components/subscriptions/services/subscription.service';
import { ContextService } from 'app/common/services/context.service';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { IHistoryVisit } from 'app/common/models/history-visit.model';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { MediaService } from 'app/common/services/media.service';
import { phoneValidator } from 'app/common/validators/phone.validator';
import { ChatService } from 'app/components/chats/chats.service';
import * as moment from 'moment';
import { AccountStatus } from 'app/common/models/account-status.model';
import {IGroupModel} from '../../../common/models/group.model';
import {GroupsService} from '../../groups/services/groups.service';
import {ClubSettingService} from '../../club-settings/club-settings.service';
import {ClubSetting} from '../../../common/models/club-setting.model';

@Component({
  selector: 'app-clients-edit',
  templateUrl: './clients-edit.component.html',
  styleUrls: ['./clients-edit.component.scss']
})
export class ClientsEditComponent extends EditItemComponent<IClientModel> {
  public static readonly componentName: string = 'ClientsEditComponent';

  public isRequestChatAvailable: boolean;
  public isSubscriptionAddAvailable: boolean;
  public Phone: string;
  protected hotkeysService: HotkeysService;
  protected hkAdd: Hotkey | Hotkey[];
  public accountStatus = AccountStatus;
  public attentionSpan = false;
  public scrollPosition: number = null;
  public isShowDetails = false;
  public isImageProfile = false;
  public ImageProfile: any;
  public windowPage: any;
  public pastVisitsCount: any;

  fieldMessages = {
    phone: {
      required: 'Это поле обязательно',
      phoneValidator: 'Введите корректный номер телефона'
    },
    email: {
      NotEmpty: 'Это поле обязательно',
      pattern: 'Введите действительный e-mail'
    },
    firstName: {
      NotEmpty: 'Это поле обязательно',
    },
    lastName: {
      NotEmpty: 'Это поле обязательно',
    },
    birthday: {
      dateValidator: 'Введите в формате дд.мм.гггг'
    }
  };

  public history: IHistoryVisit[];
  public clientGroup: IGroupModel[];
  public confirm: boolean = true;
  public confirmContact: boolean = true;

  public clubSettings: ClubSetting[];
  public isShowBtnViber = false;
  public isMobile: boolean = false;

  constructor(
    injector: Injector,
    public contextServcie: ContextService,
    public router: Router,
    public subscriptionService: SubscriptionService,
    protected service: ClientsService,
    public groupsService: GroupsService,
    protected mediaService: MediaService,
    private settingsService: ClubSettingService,
    public chatService: ChatService
  ) {
    super(injector, service);

    this.hotkeysService = injector.get(HotkeysService);
    this.hkAdd = this.hotkeysService.add(new Hotkey(['=', 'ins', 'plus'], this.hkAddPress(this), []));
    this.authService.getUserInfo().then(res => {
      this.isRequestChatAvailable = this.permissionService.isAvailable(this, 'isRequestChatAvailable', res?.role);
      this.isSubscriptionAddAvailable = true;
    });
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(e) {
    if (this.hotkeysService.isPaused()) { return; }
    if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && e.target.tagName !== 'SELECT') {
      // esc
      if (e.keyCode === 27) {
        this.close();
      }
    } else {
      // esc
      if (e.keyCode === 27) {
        e.target.blur();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenWidth();
  }

  ngOnInit() {
    super.ngOnInit()
    this.windowPage = window.innerWidth;
    window.innerWidth >= 992 ?
      document.querySelector('.app_container').scrollTop = 0 :
      document.querySelector('.app_container').scrollTop = document.documentElement.scrollTop = 0;
    this.scrollPosition = null;
    this.settingsService.getClubSettingsByClub()
      .then(settings => {
        this.clubSettings = settings;
        const alias = this.clubSettings.find(s => s.alias === 'IsShowBtnViber');
        this.isShowBtnViber = alias.value === 'true';
      });

    this.checkScreenWidth();
  }

  protected hkAddPress(that: this) {
    return () => {
      if (this.Model.id) {
        that.showSubscriptions();
      }
      return true;
    };
  }

  checkScreenWidth() {
    this.isMobile = window.innerWidth <= 540;
  }

  modelTemplate(): Promise<IClientModel> {
    const res: IClientModel = {
      id: null,
      firstName: null,
      lastName: null,
      fullName: null,
      gender: null,
      email: null,
      phone: null,
      birthday: null,
      entityStatus: 'draft',
      description: null,
      clubId: null,
      spUserId: null,
      primaryPhotoUrl: null,
      updatedAt: null,
      clubRegisteredAt: null,
      systemRegisteredAt: null,
      userId: null,
      source: 'bla bla bla',
      medias: {
        images: [],
        videos: []
      },
      accountStatus: AccountStatus.created
    };
    return Promise.resolve(res);
  }

  buildForm() {
    const invalidClubIds = [
      'cadc30204087466ba51cf0e13546b085',
      'fb4d300704304b3c8fd320d4722b0e81',
    ];
    this.form = this.formBuilder.group({
      firstName: [this.Model.firstName, [NotEmpty()]],
      lastName: [this.Model.lastName, []],
      fullName: [this.Model.fullName, []],
      gender: [this.Model.gender, []],
      email: [{
        value: this.Model.email,
      }, [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      phone: [{
        value: this.Model.phone,
        // TODO Сделать валидатор телефона под все страны мира
      }, !invalidClubIds.includes(this.routingParams.clubId) ? [phoneValidator()] : []],
      birthday: [this.Model.birthday, [dateValidator()]],
      entityStatus: [this.Model.entityStatus, [Validators.required]],
      description: [this.Model.description, Validators.maxLength(4000)],
      clubId: [this.Model.clubId, []],
      spUserId: [this.Model.spUserId, []],
      primaryPhotoUrl: [this.Model.primaryPhotoUrl, []],
      updatedAt: [this.Model.updatedAt, []],
      clubRegisteredAt: [this.Model.clubRegisteredAt, []],
      systemRegisteredAt: [this.Model.systemRegisteredAt, []],
      source: [this.Model.source, []],
      medias: [this.Model.medias, []],
    });
    if (this.Model.phone) {
      this.Phone = this.Model.phone.slice(1).replace(/[^0-9]/g, '')
    }
  };

  useFromSP() {
    this.Model.firstName = this.Model.firstNameSP;
    this.Model.lastName = this.Model.lastNameSP;
  }

  useFromSPContact() {
    this.Model.phone = this.Model.phoneNumberSP;
    this.Model.email = this.Model.emailSP;
  }

  showSubscriptions() {
    this.router.navigate(['../../clubplans-sale'], { relativeTo: this.route, queryParams: { clientId: this.Model.id } });
  }

  showGroup(group: IGroupModel, event?: any) {
    if (event && (event.button === 2 || event.ctrlKey)) {
      const url = this.router.createUrlTree(['../../clubgroups/' + group.id], { relativeTo: this.route });
      window.open(url.toString());
      return false;
    }
    this.router.navigate(['../../clubgroups/' + group.id], { relativeTo: this.route });
  }

  removeClientGroup(group: IGroupModel) {
    this.alertsService.showConfirm({
      header: 'Исключить клиента из группы?',
      buttons: [{
        title: 'Исключить',
        callback: () => {
          const userId = this.Model.userId ? this.Model.userId : this.Model.id;
          this.groupsService.removeUser(group.id, userId)
            .then(() => {
              this.clientGroup = this.clientGroup.filter((item) => item.id !== group.id);
            });
        }
      }, {
        title: 'Не исключать'
      }]
    });
  }

  setShowDetails() {
    this.isShowDetails = !this.isShowDetails;
    localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
  }

  setHideDetails() {
    this.isShowDetails = false;
    localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
  }

  checkPhotoUser(medias) {
    for (const img of medias.images) {
      if (img.isDefault) {
        this.isImageProfile = true;
        this.ImageProfile = img.url;
      }
    }
  }

  changePhotoUser(event: any) {
    this.isImageProfile = true;
    this.ImageProfile = event.body;
  }

  afterModelInit() {
    const _StorageIsShowDetails = localStorage.getItem('isShowDetails');
    if (!_StorageIsShowDetails) {
      localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
    } else {
      this.isShowDetails = JSON.parse(_StorageIsShowDetails);
    }

    if (!this.Model.id) {
      this.isShowDetails = true;
    }
    if (this.Model.id) {
      this.checkPhotoUser(this.Model.medias)
      this.service.history(this.Model.id)
        .then(res => {
          this.history = res.history;
          this.pastVisitsCount = res.pastVisitsCount;
        });
      this.service.getByClientGroup(this.Model.id)
        .then(res => {
          this.clientGroup = res;
        });
      this.service.getByClientGroup(this.Model.id)
        .then(res => {
          this.clientGroup = res;
        });

        this.confirm = this.Model.firstName !== this.Model.firstNameSP
          || this.Model.lastName !== this.Model.lastNameSP;
        this.confirmContact = this.Model.phone !== this.Model.phoneNumberSP
          || this.Model.email !== this.Model.emailSP;
    }
    // this.photoRedirect = true;
  }

  openImage(event: any) {
    this.mediaService.saveRedirectUrl();
    var url = this.contextService.makeContextUrl('image/' + event);
    this.router.navigate([url]);

  }

  async requestChat() {
    const chat = await this.chatService.request(this.Model.id);
    this.chatService.saveRedirectUrl();
    this.router.navigate([`/${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/chats/id/${chat.chat.id}`]);
  }

  goToClubSchedule(id: string, date: string, visitId: string, event?: any) {
    if (event && (event.button === 2 || event.ctrlKey)) {
      if (id === '00000000000000000000000000000000') {
        const url = this.router.createUrlTree([`/${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/clubclients/${this.routingParams.id}/visit/${visitId}`]);
        window.open(url.toString());
        return false;
      }
      const _url = this.router.createUrlTree([`/${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/clubschedule/${moment.utc(date).format('DD.MM.YYYY')}/${id}`]);
      window.open(_url.toString());
      return false;
    }
    if (id === '00000000000000000000000000000000') {
      return this.router.navigate([`/${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/clubclients/${this.routingParams.id}/visit/${visitId}`]);
    }
    this.router.navigate([`/${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/clubschedule/${moment.utc(date).format('DD.MM.YYYY')}/${id}`]);
  }

  _newClientId : string;
  public close(id?: string) {
    this.setHideDetails();
    this._newClientId = id;
    super.close();
  }

  public _close() {
    if (this._newClientId !== undefined)
      this.router.navigate([`../${this._newClientId}`], { relativeTo: this.route });
    else
      super._close();
  }
}
