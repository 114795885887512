<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
      Профиль Sport Priority
    </span>
  </div>
</header>

<div class="content__body" *ngIf="componentReady">
  <form [formGroup]="formPaymentSettings" *ngIf="_isPermit('paymentSettings')">
    <div class="row">
      <div class="col-md-12">
        <div class="title-wrap">
          <div class="box-title">
            Эквайринг
          </div>
          <div class="row__save" [hidden]="formPaymentSettings.untouched || formPaymentSettings.invalid">
            <span class="row__save_save" (click)="formPaymentSettingsSave()">
              <span class="icon-check icon-check_color">
                <span class="icon-check__left"></span>
                <span class="icon-check__right"></span>
              </span>
              <span class="title">Сохранить</span>
            </span>
            <span class="row__save_cancel" (click)="formPaymentSettingsCancel()">
              <span class="icon-cross icon-cross_color">
                <span class="icon-cross__left"></span>
                <span class="icon-cross__right"></span>
              </span>
              <span class="title">Отменить</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <select class="select"
                formControlName="gatewayType"
                (ngModelChange)="formPaymentGatewayChange()">
          <option value="">Общий</option>
          <option value="custom">Специальный</option>
        </select>
      </div>
      <div class="col-12 col-md-10">
        <textarea type="text" maxLength="500"
                  placeholder="Настройки в формате JSON (Type — обязательный парметр; стандартные параметры: Login, Password; укажите параметр ApiRestUrl, если отличается от заданного в файле конфигурации)"
                  formControlName="paramsJson" id="paymentSettingsJson" [hidden]="!formPaymentSettings.value.gatewayType"
                  (ngModelChange)="formPaymentParamsJsonChange()">
        </textarea>
        <label class="field-validation-error"
               *ngIf="formPaymentErrors('paramsJson') as errors"><span *ngFor="let err of errors">{{err}}</span></label>
      </div>
    </div>
  </form>

  <div class="row" [class.panel__four]="isShortVisible">
    <div class="col-md-12">
      <div class="title-wrap">
        <div class="box-title">
          Основные параметры
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-12" *ngIf="_isPermit('entityStatus')">
      <app-status-picker [title]="'Отображать в системе'" [ngModel]="Model.entityStatus" (ngModelChange)="Model.entityStatus=$event; modelChanged()"
        [isReadonly]="!isSystemRole"></app-status-picker>
    </div>

    <div class="col-md-6 col-sm-12" *ngIf="_isPermit('isSubscriptionSellAllowed')">
      <input type="checkbox" class="checkbox__slide" id="sell" [(ngModel)]="Model.isSubscriptionSellAllowed"
        (ngModelChange)="modelChanged()" [disabled]="!isSystemRole">
      <label for="sell" class="checkbox__slide_label">Разрешить продажу абонементов</label>
    </div>

    <div class="col-md-6 col-sm-12" *ngIf="_isPermit('isShortViewOnly')">
      <input type="checkbox" class="checkbox__slide" id="shortView" [(ngModel)]="Model.isShortViewOnly" (ngModelChange)="modelChanged()">
      <label for="shortView" class="checkbox__slide_label">Сокращенный вид в приложении</label>
    </div>

    <div class="col-md-6 col-sm-12" *ngIf="_isPermit('isApproved')">
      <input type="checkbox" class="checkbox__slide" id="checked" [(ngModel)]="Model.isApproved" (ngModelChange)="modelChanged()">
      <label for="checked" class="checkbox__slide_label">Проверено Sport Priority</label>
    </div>

    <div class="col-md-6 col-sm-12" *ngIf="_isPermit('isClubEventsAllowed')">
      <input type="checkbox" class="checkbox__slide" id="allowClubEvents" [disabled]="!isSystemRole"
        [(ngModel)]="Model.isClubEventsAllowed" (ngModelChange)="modelChanged()">
      <label for="allowClubEvents" class="checkbox__slide_label">Разрешить доступ к мероприятиям</label>
    </div>

    <div class="col-md-6 col-sm-12" *ngIf="_isPermit('isClubChatRequestDenied')">
      <input type="checkbox" class="checkbox__slide" id="disallowClubChats" [(ngModel)]="Model.isClubChatRequestDenied" (ngModelChange)="modelChanged()">
      <label for="disallowClubChats" class="checkbox__slide_label">Скрыть кнопку «Чат с клубом»</label>
    </div>

    <div class="col-md-6 col-sm-12" *ngIf="_isPermit('isBookingScheduleAllowed')">
      <input id="booking" type="checkbox" class="checkbox__slide" [(ngModel)]="Model.isBookingScheduleAllowed"
        (ngModelChange)="modelChanged()" >
      <label for="booking" class="checkbox__slide_label">Запретить запись из онлайн расписания</label>
    </div>

  </div>

  <app-club-settings *ngIf="_isPermit('settingsBlock')" [userInfo]="userInfo"></app-club-settings>

  <div class="row info">

    <!-- <div class="col-md-12" *ngIf="isAdmin">
      <app-file-upload class="fileUpload" myScrollTo [(data)]="clientsFile" [title]="'Импорт клиентов'"
        (onFinishEdit)="importClients()" [(isEditMode)]="fileUploadIsEditMode">
      </app-file-upload>
    </div> -->
    <div class="col-md-12" *ngIf="isAdmin">
      <app-test-file-upload class="fileUpload" myScrollTo [(data)]="clientsFile" [title]="'Импорт клиентов'"
        (onFinishEdit)="testImportClients()" [(isEditMode)]="fileUploadIsEditMode" [(responseVisible)]="testImportisResponseVisible" (hideResponseVisible)="hideResponseVisible($event)"
        (onFinishImportEdit) ="importClients()"
        [serverResponse]="testImportserverResponse">>
      </app-test-file-upload>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('isSubscriptionSellAllowed')">
      <div class="title-wrap">
        <div class="box-title">
          Статус клиента
        </div>
      </div>
      <div class="row__field">
        <select class="select"
          [(ngModel)]="Model.partnerType"
          (change)="modelChanged()">
          <option value="infoPartner">Инфо-партнёр</option>
          <option value="basic">Базовый</option>
          <option value="partner">Партнёр</option>
          <option value="partnerDigital">Цифровой партнёр</option>
        </select>
      </div>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('actualScheduleDate')">
      <div class="title-wrap">
        <div class="box-title">
          Дата последнего подтверждения расписания клубом:&nbsp;
          <span *ngIf="Model.actualScheduleDate; else noActualScheduleDate">{{Model.actualScheduleDate |
            date:"dd.MM.yyyy"}}</span>
          <ng-template #noActualScheduleDate>
            <span class="field">[не&nbsp;указано]</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" *ngIf="_isPermit('signingTreatyDate')">
      <app-text-edit myScrollTo [(ngModel)]="Model.signingTreatyDate" (onFinishEdit)="modelChanged()" [title]="'Дата подписания договора:'"
        [type]="'date'" [isReadonly]="!isAdmin">
      </app-text-edit>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('responsibleToRelevanceInfoUserId')">
      <app-select-edit myScrollTo [(data)]="Model.responsibleToRelevanceInfoUserId" [tooltip]="'Ответит на вопросы по расписанию'"
        [title]="'Ответственный за актуальность информации'" [selectData]="users1" (onFinishEdit)="modelChanged()"
        [isReadonly]="isClubManager">
      </app-select-edit>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('responsibleToWorkWithClientUserId')">
      <app-select-edit myScrollTo [(data)]="Model.responsibleToWorkWithClientUserId" [tooltip]="'Oтветит на вопросы по расписанию и записи на занятия'"
        [title]="'Ответственный за работу с клиентами'" [selectData]="users2" (onFinishEdit)="modelChanged()"
        [isReadonly]="isClubManager">
      </app-select-edit>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('personalSportPriorityManagerUserId')">
      <app-select-edit myScrollTo [(data)]="Model.personalSportPriorityManagerUserId" [title]="'Персональный менеджер Sport Priority'"
        [selectData]="systemUsers" (onFinishEdit)="modelChanged()" [isReadonly]="!isAdmin">
      </app-select-edit>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('comment')">
      <app-text-edit myScrollTo [(data)]="Model.comment" [tooltip]="'Отображается только для сотрудников Sport Prtiority'"
        (onFinishEdit)="modelChanged()" [title]="'Заметки'" [type]="'ckeditor'" maxlength="650" [isReadonly]="!isSystemRole">
      </app-text-edit>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('currentTariff')">
      <app-text-edit myScrollTo [(data)]="Model.currentTariff" (onFinishEdit)="modelChanged()" [title]="'Текущий тариф'"
        [type]="'input'" maxlength="650" [isReadonly]="!_isPermit('currentTariff_Editable')">
      </app-text-edit>
    </div>

    <div class="col-md-12" *ngIf="_isPermit('paymentCardBonusPercent')">
      <app-text-edit myScrollTo [(data)]="Model.paymentCardBonusPercent" renderZero (onFinishEdit)="modelChanged()" [title]="'Бонусы за покупку, 0&ndash;5%'"
        [type]="'input'" maxlength="650" [isReadonly]="!_isPermit('paymentCardBonusPercent_Editable')">
      </app-text-edit>
    </div>

    <div class="col-md-12">
      <div class="box-title">Величина комиссий (карта/СБП), %  (город {{Model.comissions.cityIsAttract ? '' : 'не'}}активный, статус {{Model.comissions.partnerType}})</div>
      <div>ВОО без авторизации: {{ Model.comissions.values.widgetPayment.anon }} / {{ Model.comissions.values.widgetPayment.sbp.anon }}</div>
      <div>ВОО с авторизацией: {{ Model.comissions.values.widgetPayment.auth }} / {{ Model.comissions.values.widgetPayment.sbp.auth }}</div>
      <div>Новый клиент в приложении, приведённый Агентом: {{ Model.comissions.values.application.new }} / {{ Model.comissions.values.application.sbp.new }}</div>
      <div>Приложение клиенты клуба, не новый клиент: {{ Model.comissions.values.application.old }} / {{ Model.comissions.values.application.sbp.old }}</div>
      <div>ВГПС без авторизации: {{ Model.comissions.values.widgetCertificate.anon }} / {{ Model.comissions.values.widgetCertificate.sbp.anon }}</div>
      <div>ВГПС с авторизацией: {{ Model.comissions.values.widgetCertificate.auth }} / {{ Model.comissions.values.widgetCertificate.sbp.auth }}</div>
    </div>
  </div>

  <app-dictionary *ngIf="_isPermit('dictionaryBlock')"></app-dictionary>

  <div *ngIf="isSystemRole">
    <div class="box-title">Удаление клуба</div>
    <div class="">
      <button class="btn btn-alert" (click)="delete()">
        <span app-key-help [key]="'deleteText'"></span>
        клуб
      </button>
    </div>
  </div>
</div>
