import { Component, OnInit, ElementRef, AfterViewInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-test-file-upload',
  templateUrl: './test-file-upload.component.html',
  styleUrls: ['./test-file-upload.component.scss']
})
export class TestFileUploadComponent implements OnInit {
  @Input() isReadonly;
  @Input() title;

  _isEditMode: boolean;
  get isEditMode(): boolean { return this._isEditMode; }
  @Input('isEditMode')
  set isEditMode(value: boolean) {
    this._isEditMode = value;
    this._isEditMode || this.enableEdit && this.onCancel();
  }
  @Input('responseVisible') responseVisible: boolean;
  @Output() hideResponseVisible = new EventEmitter();

  // то, на что биндится компонент.
  @Input() data: FileList;
  @Output() dataChange = new EventEmitter<FileList>();
  @Output() onFinishEdit = new EventEmitter();
  @Output() onFinishImportEdit = new EventEmitter();
  @Input() serverResponse: any;

  // текущее состояние. Сбрасывается на data при отмене редактирования.
  public tempData: FileList;
  public el;
  isAllClientsExpanded: boolean = false;
  get enableEdit(): boolean {
    return this.tempData && this.tempData.length > 0;
  }

  constructor(
    private elRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.tempData = this.data;
  }
  toggleAllClients() {
    this.isAllClientsExpanded = !this.isAllClientsExpanded;
  }
  ngAfterViewInit() {
    this.el = this.elRef.nativeElement.querySelector('.fileInput');
  }

  onSelectFile(event: any) {
    debugger;
    this.tempData = event.target.files;
    this.dataChange.emit(this.tempData);
    
  }

  onBeginEdit() {
    this.hideResponseVisible.emit(false)
    if (!this.isReadonly) {
      this.tempData = this.data;
      this.el.click();
    }
  }
  onImport() {
    this.dataChange.emit(this.tempData);
    this.onFinishImportEdit.emit(this.tempData);
  }
  onSave() {
    // debugger; 
    this.dataChange.emit(this.tempData);
    this.onFinishEdit.emit();
  }

  onCancel() {

    this.hideResponseVisible.emit(false)
    this.tempData = undefined;
    this.dataChange.emit(this.tempData);
    this.el.value = '';
  }
}

