import {CancelAllClassReq} from '../../../common/models/cancel-all-class.model';
import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { IClassItemModel } from 'app/common/models/class-item.model';
import { TableListService } from 'app/common/table-list/table-list.service';
import { CoursesService } from 'app/components/courses/services/courses.service';
import { CoachesService } from 'app/components/coaches/services/coaches.service';
import { ClubroomsService } from 'app/components/clubrooms/services/clubrooms.service';
import { AuthService } from 'app/common/services/auth.service';
import { PersistenceService } from 'app/common/services/persistence.service';
import { ICoachModel } from 'app/common/models/coach.model';
import { IClubroomModel } from 'app/common/models/clubroom.model';
import { ICourseModel } from 'app/common/models/course.model';

@Injectable()
export class ScheduleService extends TableListService<IClassItemModel> {

  controlState = {
    mode: 'week',
    baseDate: '',
    toDate: '',
    incrementRequestCount: true
  };

  private coaches = [];
  private rooms = [];
  private courses = [];

  constructor(
    injector: Injector,
    private coursesService: CoursesService,
    private coachService: CoachesService,
    private roomsService: ClubroomsService,
    public authService: AuthService,
    public persistenceService: PersistenceService
  ) {
    super(injector);
  }

  getOtherModels() {
    return Promise.all([
      this.coachService.getItemList(),
      this.coursesService.getItemList(),
      this.roomsService.getItemList()
    ]).then(x => {
      this.coaches = x[0];
      this.courses = x[1];
      this.rooms = x[2];
    });
  }

  getColorBy() {
    const cb = window.localStorage.getItem('colorBy');
    if (cb) { return JSON.parse(cb); }
    return 'course';
  }

  setColorBy(value) {
    window.localStorage.setItem('colorBy', JSON.stringify(value));
  }

  getShowEmpty() {
    // TODO: сделать отдельный сервис для хранения всех настроек в одном месте.
    const sh = window.localStorage.getItem('showEmptyRows');
    if (sh) { return JSON.parse(sh); }
    return true;
  }

  setShowEmpty(value) {
    window.localStorage.setItem('showEmptyRows', JSON.stringify(value));
  }

  getCopyMode() {
    const cm = window.localStorage.getItem('isInCopyMode');
    if (cm) { return JSON.parse(cm); }
    return false;
  }

  setCopyMode(value) {
    if (value === true) { this.controlState.incrementRequestCount = false; } else { this.controlState.incrementRequestCount = true; }
    window.localStorage.setItem('isInCopyMode', JSON.stringify(value));
  }

  getDraftViewMode() {
    const cm = window.localStorage.getItem('isDraftViewMode');
    if (cm) { return JSON.parse(cm); }
    return true;
  }

  setDraftViewMode(value) {
    window.localStorage.setItem('isDraftViewMode', JSON.stringify(value));
  }

  getFromCurrentDayMode() {
    const cm = window.localStorage.getItem('fromCurrentDayMode');
    if (cm) { return JSON.parse(cm); }
    return false;
  }

  setFromCurrentDayMode(value) {
    window.localStorage.setItem('fromCurrentDayMode', JSON.stringify(value));
  }

  getCellUnknownMode() {
    const cm = window.localStorage.getItem('cellUnknownMode');
    if (cm) { return JSON.parse(cm); }
    return false;
  }

  setCellUnknownMode(value) {
    window.localStorage.setItem('cellUnknownMode', JSON.stringify(value));
  }

  getBackgroundColorBusyCellsMode() {
    const cm = window.localStorage.getItem('backgroundColorBusyCellsMode');
    if (cm) { return JSON.parse(cm); }
    return false;
  }

  setBackgroundColorBusyCellsMode(value) {
    window.localStorage.setItem('backgroundColorBusyCellsMode', JSON.stringify(value));
  }

  getDisplayCategoryMode() {
    const cm = window.localStorage.getItem('displayCategoryMode');
    if (cm) { return JSON.parse(cm); }
    return 'room';
  }

  setDisplayCategoryMode(value) {
    window.localStorage.setItem('displayCategoryMode', JSON.stringify(value));
  }

  getDisplayExtraDaysMode() {
    const cm = window.localStorage.getItem('displayExtraDaysMode');
    if (cm) { return JSON.parse(cm); }
    return 1;
  }

  setDisplayExtraDaysMode(value) {
    window.localStorage.setItem('displayExtraDaysMode', JSON.stringify(value));
  }

  getCompactScheduleMode() {
    const cm = window.sessionStorage.getItem('compactScheduleMode');
    if (cm) { return typeof JSON.parse(cm) === 'boolean' ? 'week' : JSON.parse(cm) }
    return 'week';
  }

  setCompactScheduleMode(value) {
    window.sessionStorage.setItem('compactScheduleMode', JSON.stringify(value));
  }

  getFilters() {
    const value = window.localStorage.getItem('scheduleFilters');

    return value ? JSON.parse(value) : null;
  }

  setFilters(value) {
    window.localStorage.setItem('scheduleFilters', JSON.stringify(value));
  }

  getMemoryDate() {
    const cb = window.sessionStorage.getItem('memoryDate');
    if (cb) { return JSON.parse(cb); }
    return 'course';
  }

  setMemoryDate(value) {
    window.sessionStorage.setItem('memoryDate', JSON.stringify(value));
  }

  getItemList() {
    return this.apiService.schedule.getList(this.contextService.getRoutingParams(), this.controlState.baseDate, this.controlState.toDate, this.controlState.incrementRequestCount);
  }

  getSingleItem(item: IClassItemModel): Promise<IClassItemModel> {
    const persistenceModel = this.persistenceService.getModel();
    if (persistenceModel) {
      return Promise.resolve(persistenceModel);
    }
    return this.apiService.schedule.getItem(item, this.routingParams);
  }

  save(model: IClassItemModel) {
    return this.apiService.schedule.saveItem(this.contextService.getRoutingParams(), model);
  }

  create(model: IClassItemModel) {
    return this.save(model);
  }

  cancelClasses(model, isVirtual: boolean) {
    return this.apiService.schedule.cancelClasses(this.contextService.getRoutingParams(), model, isVirtual);
  }

  getCoaches(): Promise<Array<ICoachModel>> {
    return Promise.resolve(this.coaches);
  }

  getRooms(): Promise<Array<IClubroomModel>> {
    return Promise.resolve(this.rooms);
  }

  getCourses(): Promise<Array<ICourseModel>> {
    return Promise.resolve(this.courses);
  }

  getLevels() {
    return Promise
      .resolve({
        levels: [
          'beginner',
          'advanced',
          'any'
        ],
      });
  }

  actualSchedule() {
    return this.apiService.schedule.actualSchedule(this.routingParams);
  }

  getVisits(classId, incrementRequestCount: boolean = true): Promise<any> {
    return this.apiService.schedule.getVisits(this.routingParams, classId, false, incrementRequestCount);
  }

  book(model): Promise<any> {
    return this.apiService.schedule.book(this.routingParams, model);
  }

  bookFree(model): Promise<any> {
    return this.apiService.schedule.bookFree(this.routingParams, model);
  }

  changeSubscriptionPlan(model) {
    return this.apiService.schedule.changeSubscriptionPlan(this.routingParams, model);
  }

  validateSubscriptionNotSuitable(model) {
    return this.apiService.schedule.validateSubscriptionNotSuitable(this.routingParams, model);
  }

  cancelBooking(client, withoutNotifications = false, incrementRequestCount: boolean = true, handleResult: boolean = true) {
    return this.apiService.schedule.cancelBooking(this.routingParams, client, withoutNotifications, incrementRequestCount, handleResult);
  }

  confirmVisited(client, incrementRequestCount: boolean = true, handleResult: boolean = true) {
    return this.apiService.schedule.confirmVisited(this.routingParams, client, incrementRequestCount, handleResult);
  }

  confirmQueued(client, incrementRequestCount: boolean = true, handleResult: boolean = true) {
    return this.apiService.schedule.confirmQueued(this.routingParams, client, incrementRequestCount, handleResult);
  }

  confirmMissed(client, incrementRequestCount: boolean = true, handleResult: boolean = true) {
    return this.apiService.schedule.confirmMissed(this.routingParams, client, incrementRequestCount, handleResult);
  }

  getSubscriptions(courseId: string) {
    return this.apiService.subscriptions.getCourseSubscriptions(this.routingParams, courseId);
  }

  getGroups(clubId: string, incrementRequestCount: boolean = true) {
    return this.apiV2Service.groups.getByClub(clubId, incrementRequestCount)
  }

  getClassGroups(classItem: IClassItemModel, startDate: any) {
    return classItem.id && classItem.id != "00000000000000000000000000000000"
      ? this.apiV2Service.groups.getByClass(classItem.id, null, null)
      : this.apiV2Service.groups.getByClass(null, classItem.prototypeId, startDate);
  }

  getClassVisits(classId: string, classPrototypeId: string, startDate: any, cropName: string, incrementRequestCount: boolean = true): Promise<any> {
    return classId && classId != "00000000000000000000000000000000"
      ? this.apiV2Service.schedule.getByClass(classId, null, null, cropName, incrementRequestCount)
      : this.apiV2Service.schedule.getByClass(null, classPrototypeId, startDate, cropName, incrementRequestCount);
  }

  createVisitForGroupUser(model, incrementRequestCount: boolean = true): Promise<any> {
    return this.apiV2Service.schedule.createVisitForGroupUser(model, incrementRequestCount);
  }

  confirmForGroupUser(model, handleResult: boolean = true): Promise<any> {
    return this.apiV2Service.schedule.confirmForGroupUser(model, handleResult);
  }

  missForGroupUser(model, handleResult: boolean = true): Promise<any> {
    return this.apiV2Service.schedule.missForGroupUser(model, handleResult);
  }

  cancelForGroupUser(model, handleResult: boolean = true): Promise<any> {
    return this.apiV2Service.schedule.cancelForGroupUser(model, handleResult);
  }

  cancelAllClass(req: CancelAllClassReq) {
    return this.apiService.schedule.cancelAllClass(this.contextService.getRoutingParams(), req)
  }

  // ----- Методы для экспорта
  getExportField(val: string) {
    const cm = window.localStorage.getItem(val);
    if (cm) { return (cm == "false" || cm == null) ? false : true;; }
    return false;
  }
}
