import { Validators } from '@angular/forms';
import { Injector, Component } from '@angular/core';
import { CoachesService } from 'app/components/coaches/services/coaches.service';
import { ICoachModel } from 'app/common/models/coach.model';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { EditItemComponent } from 'app/common/edit-item.component';
import { dateValidator } from 'app/common/validators/date.validator';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { MediaService } from 'app/common/services/media.service';
import { phoneValidator } from 'app/common/validators/phone.validator';
import * as moment from 'moment';
import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import { ClubSettingService } from 'app/components/club-settings/club-settings.service';

@Component({
  selector: 'app-coach-edit',
  templateUrl: './coach-edit.component.html',
  styleUrls: ['./coach-edit.component.scss'],
})
export class CoachEditComponent extends EditItemComponent<ICoachModel> {
  public static readonly componentName: string = 'CoachEditComponent';

  public isCoachRatesAvailable: boolean;

  public draftCoaches;
  fieldMessages = {
    phone: {
      phoneValidator: 'Введите корректный номер телефона'
    },
    firstName: {
      NotEmpty: 'Это поле обязательно',
    },
    birthday: {
      dateValidator: 'Введите в формате дд.мм.гггг'
    },
    color: {
      required: 'Необходимо указать цвет'
    }
  };

  constructor(
    injector: Injector,
    private readonly alertService: AlertsService,
    private readonly clubSettingService: ClubSettingService,
    protected mediaService: MediaService,
    protected service: CoachesService
  ) {
    super(injector, service);
    this.authService.getUserInfo().then(res => {
      this.isCoachRatesAvailable = this.permissionService.isAvailable(this, 'isCoachRatesAvailable', res?.role);
    });
  }

  public isSalaryCalculationAvailable: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.clubSettingService.getClubSettingsByClub()
      .then(val => this.isSalaryCalculationAvailable = val.filter(x => x.alias === "IsSalaryCalculationAvailable")[0]?.value === "true");
  }

  modelTemplate(): Promise<ICoachModel> {
    const res: ICoachModel = {
      firstName: null,
      lastName: null,
      group: null,
      sortOrder: null,
      position: null,
      phone: null,
      birthday: null,
      entityStatus: EntityStatus.draft,
      imageUrl: '',
      id: null,
      color: '#93a4ad',
      chargeRateUntilDate: null,
      medias: {
        images: [],
        videos: []
      },
      description: null,
      experience: null,
      quote: null,
      workApproach: null
    };
    return Promise.resolve(res);
  }

  buildForm() {
    let localStorageDraftCoaches: any = localStorage.getItem(`draftCoaches_${this.Model.id}`);
    const limit = 24 * 3600 * 1000;
    if (localStorageDraftCoaches !== null) {
      localStorageDraftCoaches = JSON.parse(localStorageDraftCoaches);
      if (+new Date() - localStorageDraftCoaches.limit > limit) {
        localStorage.removeItem(`draftCoaches_${this.Model.id}`);
      } else {
        this.Model.phone = localStorageDraftCoaches.phone;
        this.Model.firstName = localStorageDraftCoaches.firstName;
        this.Model.lastName = localStorageDraftCoaches.lastName;
        this.Model.position = localStorageDraftCoaches.position;
        this.Model.group = localStorageDraftCoaches.group;
        this.Model.sortOrder = localStorageDraftCoaches.sortOrder;
        this.Model.description = localStorageDraftCoaches.description;
        this.Model.workApproach = localStorageDraftCoaches.workApproach;
        this.Model.quote = localStorageDraftCoaches.quote;
        this.Model.birthday = localStorageDraftCoaches.birthday;
        this.Model.color = localStorageDraftCoaches.color;
        this.Model.chargeRateUntilDate = localStorageDraftCoaches.chargeRateUntilDate;
        localStorage.removeItem(`draftCoaches_${this.Model.id}`);
      }
    }

    this.form = this.formBuilder.group({
      firstName: [this.Model.firstName, [NotEmpty()]],
      lastName: [this.Model.lastName, []],
      position: [this.Model.position, []],
      group: [this.Model.group, []],
      sortOrder: [this.Model.sortOrder, []],
      phone: [this.Model.phone, [phoneValidator()]],
      birthday: [this.Model.birthday, [dateValidator()]],
      color: [this.Model.color, [Validators.required]],
      chargeRateUntilDate: [this.Model.chargeRateUntilDate, []],
      entityStatus: [this.Model.entityStatus, [Validators.required]],
      medias: [this.Model.medias, []],
      description: [this.Model.description, []],
      experience: [this.Model.experience, []],
      quote: [this.Model.quote, []],
      workApproach: [this.Model.workApproach, []]
    });
    if (this.Model.firstName !== null) {
      this.Model.firstName = this.Model.firstName.trim()
    }
    if (this.Model.lastName !== null) {
      this.Model.lastName = this.Model.lastName.trim()
    }
    if (this.Model.position !== null) {
      this.Model.position = this.Model.position.trim()
    }
    if (this.Model.group !== null) {
      this.Model.group = this.Model.group.trim()
    }
  };

  openImage(event: any) {

    this.draftCoaches = {
      phone: this.form.value.phone,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      position: this.form.value.position,
      group: this.form.value.group,
      sortOrder: this.form.value.sortOrder,
      description: this.form.value.description,
      workApproach: this.form.value.workApproach,
      quote: this.form.value.quote,
      birthday: this.form.value.birthday,
      color: this.form.value.color,
      chargeRateUntilDate: this.form.value.chargeRateUntilDate,
      limit: +new Date()
    };
    localStorage.removeItem(`draftCoaches_${this.Model.id}`);
    localStorage.setItem(`draftCoaches_${this.Model.id}`, JSON.stringify(this.draftCoaches));

    this.mediaService.saveRedirectUrl();
    const url = this.contextService.makeContextUrl('image/' + event);
    this.router.navigate([url]);
  }

  delete(): void {
    if (this.isSalaryCalculationAvailable && !moment(this.Model.chargeRateUntilDate, 'DD.MM.YYYY').isValid())
      this.alertService.alert.next({
        type: 'danger',
        message: 'Укажите дату завершения начислений ЗП',
        header: 'Заполните данные',
        position: 'top',
        timeout: 2000
      });
    else
      super.delete();
  }
}
